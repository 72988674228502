import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerContractList.css";
import {
  FormGroup,
  FormLabel,
  OutlinedInput,
  Stack,
  styled,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./PowerContractList";
import { setPowerContractorInfo } from "../../types/master/SetPowerContractList";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { API_URL, RESULT_CODE } from "../../common/constant";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { CommonResponse } from "../../types/common/Api";
import {
  powerBpAndSubArea,
  areaInfo,
  bpInfo,
} from "../../types/master/SetPowerContractList";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  contractorName: boolean;
  powerContractCode: boolean;

  areaId: boolean;
  bpId: boolean;
  powerKind: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  contractorName: false,
  powerContractCode: false,

  areaId: false,
  bpId: false,
  powerKind: false,
};

/**
 * 発電契約者編集・新規登録モーダル
 * @param toOpen
 * @param HaldleClose
 * @param mode
 * @param contractIdPK
 * @returns
 */
const SetPowerContractList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  contractIdPK: number
) => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアリスト作成
  const { areaOptions } = useAreaUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  //初期化用
  const initInfo: setPowerContractorInfo = {
    contractId: 0,
    contractorName: "",
    powerContractCode: "",
    bpId: 0,
    areaId: "00",
    remarks: "",
  };

  // エリアプルダウンリスト
  const [editModeAreaIdList, setEditModeAreaIdList] = useState<string[]>([
    "00",
  ]);
  const [addModeAreaIdList, setAddmodeAreaIdList] = useState<string[]>(["00"]);

  // 事業者情報
  const [bpInfo, setBpInfo] = useState<bpInfo[]>([{ id: 0, name: "" }]);
  const [bpCode, setBpCode] = useState<string>("");

  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ
  const [powerContractorInfo, setPowerContractorInfo] =
    useState<setPowerContractorInfo>(initInfo);
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [isLoading, setIsLoading] = useState<boolean>(false);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化

  useEffect(() => {
    // モーダルオープン
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        // 編集モード
        (() => {
          Promise.all([
            getDataLinkedContractId(contractIdPK),
            getPowerBgAndSubjectArea(contractIdPK),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        // 新規登録モード
        (() => {
          getPowerBgAndSubjectArea(powerContractorInfo.contractId).finally(
            () => {
              setIsLoading(false);
            }
          );
        })();
        setBpCode("");
      }
    } else {
      setAddmodeAreaIdList(["00"]);
      setEditModeAreaIdList(["00"]);
      setPowerContractorInfo(initInfo);
      setBpCode("");
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  const convertAreaData = (result: string[]) => {
    const retAreaData: string[] = ["00"];
    result.map((areaId) => {
      const areaData: areaInfo = { id: "", name: "" };
      areaData.id = areaId.toString();
      retAreaData.push(areaData.id);
    });
    return retAreaData;
  };

  function setAreaId(id: number) {
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    tmpAreaInfo.areaId = areaIdString;

    setPowerContractorInfo(tmpAreaInfo);
  }

  // 事業者と紐付くエリア(発電契約者が未登録)を取得
  async function getPowerBgAndSubjectArea(contractId: number) {
    return api
      .get<number, AxiosResponse<CommonResponse<powerBpAndSubArea>>>(
        API_URL.GET_POWER_BP_AND_SUBAREA,
        {
          params: { contractorId: contractId },
        }
      )
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_BP_DATA) {
          setDialogMessage(languageContext.words.setting_can_not_display_bp);
          setDigOpen(true);
        }

        if (res.data.resultCode === RESULT_CODE.NO_AREA) {
          setDialogMessage(languageContext.words.setting_can_not_select_area);
          setDigOpen(true);
        }

        // 事業者プルダウン表示項目セット
        setBpInfo([{ id: res.data.result.bpId, name: res.data.result.bpName }]);
        setBpCode(res.data.result.bpCode);
        setAddmodeAreaIdList(convertAreaData(res.data.result.areaIds));
      });
  }

  //テキストフィールドの中身が変化したら、powerContractorInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    switch (name) {
      case "contractorName":
        tmpInfo.contractorName = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      case "powerContractCode":
        tmpInfo.powerContractCode = value;
        break;
      default:
        break;
    }
    setPowerContractorInfo(tmpInfo);
  }

  // 削除(論理削除)
  const Delete = async (contractorId: number | undefined) => {
    setIsLoading(true);
    await api
      .post<CommonResponse<number | undefined>>(
        API_URL.DELETE_POWERCONTRACTOR_INFO,
        {
          contractorId: contractorId,
          areaId: powerContractorInfo.areaId,
        }
      )
      .then((response) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(languageContext.words.power_contractor_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.power_contractor_failed_delete);
        setDigOpen(true);
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  const DoModeJob = () => {
    if (mode === modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // 描画している内容を保存する
  const AddDatas = () => {
    let error = false;
    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      // 事業者をセット
      const postPowerContractInfo: setPowerContractorInfo = Object.assign(
        {},
        powerContractorInfo
      );

      postPowerContractInfo.bpId = bpInfo[0].id;
      setIsLoading(true);
      api
        .post<CommonResponse<setPowerContractorInfo>>(
          API_URL.ADD_POWERCONTRACTOR_INFO,
          postPowerContractInfo
        )
        .then((res) => {
          setIsLoading(false);
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.power_contractor_duplication_message
            );
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_contractor_added);
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_contractor_failed_add);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  };

  //正しい情報かどうか検査
  const isCorrectInfo = (): boolean => {
    let error = false;

    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerContractorInfo?.contractorName === "") {
      error = true;
      tempInputErrors.contractorName = true;
    }
    if (powerContractorInfo?.powerContractCode === "") {
      error = true;
      tempInputErrors.powerContractCode = true;
    }
    if (powerContractorInfo?.areaId === "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    setInputError(tempInputErrors);
    return error;
  };

  // 発電契約者1レコード取得
  const getDataLinkedContractId = async (contractorId: number) => {
    let responseData: setPowerContractorInfo;
    return api
      .get<CommonResponse<setPowerContractorInfo>>(
        API_URL.GET_RECORD_CONTRACTOR,
        {
          params: { contractorId: contractorId },
        }
      )
      .then((response) => {
        editflg.current = true;
        responseData = response.data.result as setPowerContractorInfo;
        setPowerContractorInfo(responseData);
        const areaId: string[] = [];
        areaId.push(responseData.areaId);

        setEditModeAreaIdList(convertAreaData(areaId));
      })
      .catch((err) => {
        console.log(err);
        setPowerContractorInfo(initInfo);
      });
  };

  //データを更新する
  function UpdateDatas() {
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      setIsLoading(true);
      api
        .post<CommonResponse<setPowerContractorInfo>>(
          API_URL.UPDATE_POWERCONTRACTOR_INFO,
          powerContractorInfo
        )
        .then((res) => {
          setIsLoading(false);
          setDigOpen(true);
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.power_contractor_duplication_message
            );
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_contractor_updated);
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.power_contractor_failed_update
          );
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.generation_contractors_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(powerContractorInfo?.contractId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack sx={{ width: "550px" }}>
        <Stack direction="column" spacing={0}>
          <CustomBoxBig>
            <FormLabel required>
              {languageContext.words.gen_contractors_name}
            </FormLabel>
            <CustomTextFieldBig
              type="text"
              value={powerContractorInfo?.contractorName}
              onChange={onChangeValue}
              name="contractorName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.contractorName}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.plan_submitter_code}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={powerContractorInfo?.powerContractCode}
              onChange={onChangeValue}
              name="powerContractCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.powerContractCode}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <Stack />

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_producer}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={bpInfo[0].name}
                disabled={true}
                error={inputError.bpId}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_bp_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={bpCode}
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall></CustomBoxSmall>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>{languageContext.words.area}</FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                name="volLevel"
                label={languageContext.words.none}
                value={powerContractorInfo?.areaId}
                options={
                  mode === modalMode.editMode
                    ? areaOptions(editModeAreaIdList) // 編集モードは登録済エリア固定
                    : areaOptions(addModeAreaIdList) // 新規登録モードは登録可能全エリア
                }
                input={<OutlinedInput label="Name" />}
                onChange={(e: number) => {
                  setAreaId(e);
                }}
                error={inputError.areaId}
                disabled={mode === modalMode.editMode ? true : false}
              ></CustomSelectListSmall>
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxBig>
              <CustomModalTypography gutterBottom>
                {languageContext.words.remarks}
              </CustomModalTypography>
              <CustomTextFieldBig
                type="text"
                value={powerContractorInfo?.remarks}
                onChange={onChangeValue}
                name="remarks"
                inputProps={{
                  maxLength: 256,
                }}
              ></CustomTextFieldBig>
            </CustomBoxBig>
          </Stack>
        </Stack>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerContractList;
