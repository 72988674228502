import React, { useContext, useState, useEffect } from "react";
import { Chart as ChartJS } from "chart.js";
import Stack from "@mui/material/Stack";
import zoomPlugin from "chartjs-plugin-zoom";
import CustomSettingModal, {
  NumOfButton,
} from "../../common/customComponents/CustomSettingModal";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import { modalMode } from "./AlarmList";
import { alarmSettingType } from "../../../types/system/SetAlarmList";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { kMaxLength } from "buffer";
import { format, subDays } from "date-fns";
import { ja } from "date-fns/locale";
import { useAuthedApi } from "../../../common/axios";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

ChartJS.register(zoomPlugin);

const INSERT_API = "/insertAlarmSetting";
const UPDATE_API = "/updateAlarmSetting";
const DELETE_API = "/deleteAlarmSetting";
const GET_ALERT_SETTING_API = "/getAlertSettingInfoLinkedPK";
const GET_VALID_ARALM_LIST_API = "/getValidAlarmSettingList";
const SET_SOCKETIO_ALARM_API = "/setSocketIoAlarm";

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  alarmName: boolean;
  message: boolean;
  month: boolean;
  day: boolean;
  hour: boolean;
  minute: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  alarmName: false,
  message: false,
  month: false,
  day: false,
  hour: false,
  minute: false,
};
// アラーム編集画面
const SetAlarmSettings = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  alarmIdForEdit: number
) => {
  const languageContext = useContext(LanguageContext);

  //通信エラー
  const api = useAuthedApi();

  // 初期化用format(new Date(), 'MMM Do dddd', {locale: ja}
  const initializeAlarmSetInfo: alarmSettingType = {
    alarmName: "",
    alarmId: 0,
    status: 1,
    month: Number(format(new Date(), "MM", { locale: ja })).toString(),
    day: Number(format(new Date(), "dd", { locale: ja })).toString(),
    hour: Number(format(new Date(), "HH", { locale: ja })).toString(),
    minute: Number(format(new Date(), "mm", { locale: ja })).toString(),
    repeat: 1,
    weekMon: 0,
    weekTue: 0,
    weekWeb: 0,
    weekThu: 0,
    weekFri: 0,
    weekSat: 0,
    weekSun: 0,
    message: "",
  };
  const [alarmSettingInfo, setAlarmSettingInfo] = useState<alarmSettingType>(
    initializeAlarmSetInfo
  );
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputerror] = useState<ErrorInput>(initErrorInput); //各セルにエラー属性を付与するか否かを管理する

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(false);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //初期化する
  useEffect(() => {
    setAlarmSettingInfo(initializeAlarmSetInfo);
  }, []);

  useEffect(() => {
    if (toOpen === true) {
      if (mode === modalMode.editMode) {
        setIsLoading(true);
        (async () => {
          getAlarmSettingInfo(alarmIdForEdit).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        setAlarmSettingInfo(initializeAlarmSetInfo);
        setCheckedWeeks([]);
      }
    } else {
      setAlarmSettingInfo(initializeAlarmSetInfo);
      setInputerror(initErrorInput);
    }
  }, [toOpen]);

  // アラート設定取得
  const getAlarmSettingInfo = (alarmId: number) => {
    let responseData: alarmSettingType;
    return api
      .get(GET_ALERT_SETTING_API, { params: { id: alarmId } })
      .then((response) => {
        responseData = response.data.result as alarmSettingType;
        if (responseData.month === null) {
          responseData.month = Number(
            format(new Date(), "MM", { locale: ja })
          ).toString();
        }
        if (responseData.day === null) {
          responseData.day = Number(
            format(new Date(), "dd", { locale: ja })
          ).toString();
        }
        const tmpWeekList: string[] = [];
        if (responseData.weekMon == 1) {
          tmpWeekList.push("weekMon");
        }
        if (responseData.weekTue == 1) {
          tmpWeekList.push("weekTue");
        }
        if (responseData.weekWeb == 1) {
          tmpWeekList.push("weekWeb");
        }
        if (responseData.weekThu == 1) {
          tmpWeekList.push("weekThu");
        }
        if (responseData.weekFri == 1) {
          tmpWeekList.push("weekFri");
        }
        if (responseData.weekSat == 1) {
          tmpWeekList.push("weekSat");
        }
        if (responseData.weekSun == 1) {
          tmpWeekList.push("weekSun");
        }
        setCheckedWeeks(tmpWeekList);

        setAlarmSettingInfo(responseData);
      })
      .catch((err) => {
        console.log("getAlarmSettingInfo失敗:" + err);
        setAlarmSettingInfo(initializeAlarmSetInfo);
      });
  };

  console.log(alarmSettingInfo);
  //入力項目が変化したら、alarmSettingInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    let value = e.currentTarget.value;

    const tmpInfo: alarmSettingType = Object.assign({}, alarmSettingInfo);

    switch (name) {
      case "alarmName":
      case "message":
        tmpInfo[name] = value;
        break;
      case "month":
        if (value == "") {
          value = "1";
        }
        if (parseInt(value) >= 13) {
          value = value.slice(0, 1);
        }
        if (isSafeNumInTextField(value, 12, 1)) {
          tmpInfo[name] = value;
        }
        break;
      case "day":
        if (value == "") {
          value = "1";
        }
        if (parseInt(value) >= 32) {
          value = value.slice(0, 2);
        }
        if (isSafeNumInTextField(value, 31, 1)) {
          tmpInfo[name] = value;
        }
        break;
      case "hour":
        if (parseInt(value) >= 24) {
          value = value.slice(0, 2);
        }
        if (isSafeNumInTextField(value, 23, 0)) {
          tmpInfo[name] = value;
        }
        break;
      case "minute":
        if (parseInt(value) >= 60) {
          value = value.slice(0, 2);
        }
        if (isSafeNumInTextField(value, 59, 0)) {
          tmpInfo[name] = value;
        }
        break;
      case "repeat":
        tmpInfo[name] = Number(value);
        break;
      case "weekMon":
      case "weekTue":
      case "weekWeb":
      case "weekThu":
      case "weekFri":
      case "weekSat":
      case "weekSun":
        if (tmpInfo[name] == 1) {
          tmpInfo[name] = 0;
        } else {
          tmpInfo[name] = 1;
        }
        break;
    }
    setAlarmSettingInfo(tmpInfo);
  }

  //テキストフィールドに正しい数値若しくは空文字が入っているかチェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    //空文字チェック
    if (value === "") {
      return true;
    }
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseInt(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  //トグルが変化したら、alarmSettingInfoの中身をそのたびに変える
  function onChangeToggle(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;

    const tmpInfo: alarmSettingType = Object.assign({}, alarmSettingInfo);
    switch (name) {
      case "status": {
        const checked = e.target.checked;
        const value = checked ? 1 : 0;
        tmpInfo[name] = value;
        break;
      }
    }
    setAlarmSettingInfo(tmpInfo);
  }

  //週のチェックボックス状態管理
  const [checkedWeeks, setCheckedWeeks] = useState<string[]>([]);
  function onChangeWeekCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
    if (checkedWeeks.includes(e.target.name)) {
      setCheckedWeeks(
        checkedWeeks.filter((checkedValue) => checkedValue !== e.target.name)
      );
    } else {
      setCheckedWeeks([...checkedWeeks, e.target.name]);
    }
  }

  function checkDateTime() {
    let safeDateTime = true;
    if (alarmSettingInfo?.repeat == 0 || alarmSettingInfo?.repeat == 4) {
      //月
      const regMonth = new RegExp("^([1-9]|1[0-2])$");
      if (!regMonth.test(alarmSettingInfo?.month ?? "")) safeDateTime = false;
    }
    if (
      alarmSettingInfo?.repeat == 0 ||
      alarmSettingInfo?.repeat == 3 ||
      alarmSettingInfo?.repeat == 4
    ) {
      //日
      const regDay = getMonthRegExp(Number(alarmSettingInfo?.month));
      if (!regDay.test(alarmSettingInfo?.day ?? "")) {
        safeDateTime = false;
      } else {
        if (alarmSettingInfo?.repeat == 3) {
          // 毎月指定
          if (Number(alarmSettingInfo?.day) > 28) {
            safeDateTime = false;
          }
        } else {
          // 月の指定あり
          const date = new Date();
          date.setMonth(Number(alarmSettingInfo?.month) - 1);
          date.setDate(Number(alarmSettingInfo?.day));
          if (
            date.getFullYear() != new Date().getFullYear() ||
            date.getMonth() + 1 != Number(alarmSettingInfo?.month) ||
            date.getDate() != Number(alarmSettingInfo?.day)
          ) {
            safeDateTime = false;
          }
        }
      }
    }
    //時
    const regHour = new RegExp("^([0-9]|[0-1][0-9]|2[0-3])$");
    if (!regHour.test(alarmSettingInfo?.hour ?? "")) safeDateTime = false;

    //分
    const regMin = new RegExp("^([0-9]|[0-5][0-9])$");
    if (!regMin.test(alarmSettingInfo?.minute ?? "")) safeDateTime = false;

    return safeDateTime;
  }

  const getMonthRegExp = (month: number): RegExp => {
    switch (month) {
      case 2: {
        const date = new Date();
        date.setMonth(1);
        date.setDate(29);
        const ret =
          date.getDate() == 29
            ? RegExp("^([1-9]|[1-2][0-9])$")
            : RegExp("^([1-9]|[1-2][0-8])$");
        return ret;
      }
      case 4:
      case 6:
      case 9:
      case 11:
        return new RegExp("^([1-9]|[1-2][0-9]|30)$");
      default:
        return new RegExp("^([1-9]|[1-2][0-9]|3[0-1])$");
    }
  };

  //設定したものをDBに登録
  const saveAlarm = () => {
    //エラーinputに変更

    const tempInputErrors = Object.assign({}, initErrorInput);
    if (alarmSettingInfo?.alarmName == "") {
      tempInputErrors.alarmName = true;
    }
    if (alarmSettingInfo?.message == "") {
      tempInputErrors.message = true;
    }
    //月
    if (alarmSettingInfo?.repeat == 0 || alarmSettingInfo?.repeat == 4) {
      const regMonth = new RegExp("^([1-9]|1[0-2])$");
      if (!regMonth.test(alarmSettingInfo?.month ?? ""))
        tempInputErrors.month = true;
    }
    //日
    let regDay;
    if (alarmSettingInfo?.repeat == 0 || alarmSettingInfo?.repeat == 4) {
      regDay = getMonthRegExp(Number(alarmSettingInfo?.month));
      if (!regDay.test(alarmSettingInfo?.day ?? "")) tempInputErrors.day = true;
    }
    if (alarmSettingInfo?.repeat == 3) {
      regDay = new RegExp("^([1-9]|[1-2][0-8])$"); //毎月は必ずある日のみ設定可能
      if (!regDay.test(alarmSettingInfo?.day ?? "")) tempInputErrors.day = true;
    }
    //時
    const regHour = new RegExp("^([0-9]|[0-1][0-9]|2[0-3])$");
    if (!regHour.test(alarmSettingInfo?.hour ?? ""))
      tempInputErrors.hour = true;
    //分
    const regMin = new RegExp("^([0-9]|[0-5][0-9])$");
    if (!regMin.test(alarmSettingInfo?.minute ?? ""))
      tempInputErrors.minute = true;

    setInputerror(tempInputErrors);

    if (alarmSettingInfo?.alarmName == "" || alarmSettingInfo?.message == "") {
      //alert("アラート名称もしくはメッセージが未入力です。");
      setDialogMessage(languageContext.words.alarm_err_message_1);
      setDigOpen(true);
      return;
    }
    if (!checkDateTime()) {
      //alert("日時の入力が不適切です。");
      setDialogMessage(languageContext.words.alarm_err_message_2);
      setDigOpen(true);
      return;
    }
    if (alarmSettingInfo?.repeat == 2 && checkedWeeks.length == 0) {
      //週選択かつ曜日選択なし
      //alert("曜日を選択してください。");
      setDialogMessage(languageContext.words.alarm_err_message_3);
      setDigOpen(true);
      return;
    }
    setCheckedWeeks([]);

    //チェックをクリアしたら登録
    if (mode == modalMode.registerMode) {
      insertAlarm();
    } else {
      updateAlarm();
    }
  };

  //設定したものをDBに登録
  const insertAlarm = () => {
    setIsLoading(true);
    // 保存処理
    api
      .post(INSERT_API, alarmSettingInfo)
      .then((res) => {
        // 保存後の処理
        if (alarmSettingInfo?.status == 1) {
          setSocketAlarm();
        }
        setJobClear(true);
        setDialogMessage(languageContext.words.alarm_saved_new_setting);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log("insertAlarm失敗:" + err);
        setDialogMessage(languageContext.words.alarm_failed_save_new_setting);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //設定したものをDBに登録
  const updateAlarm = () => {
    setIsLoading(true);
    // 保存処理
    api
      .post(UPDATE_API, alarmSettingInfo)
      .then((res) => {
        // 保存後の処理
        setSocketAlarm();
        setJobClear(true);
        setDialogMessage(languageContext.words.alarm_updated_setting);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log("updateAlarm失敗:" + err);
        setDialogMessage(languageContext.words.alarm_failed_uppdate_setting);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //対象のアラート設定を削除
  const deleteAlarm = (alarmId: number) => {
    if (alarmId !== 0) {
      setIsLoading(true);
      // 削除処理
      api
        .get(DELETE_API, { params: { id: alarmId } })
        .then((res) => {
          // 保存後の処理
          setSocketAlarm();
          setJobClear(true);
          setDialogMessage(languageContext.words.alarm_deleted_setting);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log("deleteAlarm失敗:" + err);
          setDialogMessage(languageContext.words.alarm_failed_delete_setting);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //alarmIdが0（＝新規登録時）の場合は何もせず閉じる
      HaldleClose();
    }
  };

  //画面側に通知するアラートを設定
  function setSocketAlarm() {
    api
      .get(GET_VALID_ARALM_LIST_API) // 通知定義取得
      .then((response) => {
        api.get(SET_SOCKETIO_ALARM_API, { params: { list: response.data } });
      }) //cron設定
      .catch((err) => {
        console.log("setSocketAlarm失敗:" + err);
      });
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.alarm_editting}
      open={toOpen}
      onAcceptLeft={() => saveAlarm()}
      onAcceptRight={() => deleteAlarm(alarmSettingInfo?.alarmId ?? 0)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.registerMode
          ? NumOfButton.Single
          : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack
        sx={{ height: "450px", width: "880px" }}
        spacing={1}
        direction="row"
      >
        <Grid container>
          <Grid item xs={10}>
            <FormGroup>
              <FormLabel required sx={{ paddingTop: "8px" }}>
                {languageContext.words.alarm_name}
              </FormLabel>
            </FormGroup>
            <TextField
              name="alarmName"
              rows={1}
              value={alarmSettingInfo?.alarmName}
              onChange={onChangeValue}
              inputProps={{ maxLength: 64 }}
              sx={{
                input: {
                  height: "42px",
                  padding: "0px",
                  width: "600px",
                  "padding-left": "14px",
                },
              }}
              error={inputError.alarmName}
            ></TextField>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ paddingTop: "8px" }}>
              {languageContext.words.alarm_status}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="status"
                  onChange={onChangeToggle}
                  checked={alarmSettingInfo?.status == 1 ? true : false}
                />
              }
              label=""
              color="primary"
            />
          </Grid>
          <Grid item xs={5}>
            <FormLabel required sx={{ paddingTop: "8px" }}>
              {languageContext.words.timeC}
            </FormLabel>
            <Stack direction="row" spacing={0}>
              {(alarmSettingInfo?.repeat == 4 ||
                alarmSettingInfo?.repeat == 0) && (
                <TextField
                  name="month"
                  type="number"
                  onFocus={(e) => {
                    if (Number(alarmSettingInfo?.month) === 0) {
                      e.currentTarget.select();
                    }
                  }}
                  value={Number(alarmSettingInfo?.month).toFixed(0)}
                  onChange={onChangeValue}
                  inputProps={{
                    maxLength: 2,
                    min: 1,
                    max: 12,
                  }}
                  sx={{
                    input: {
                      height: "42px",
                      padding: "0px",
                      width: "60px",
                      "padding-left": "14px",
                    },
                  }}
                  error={inputError.month}
                ></TextField>
              )}
              {(alarmSettingInfo?.repeat == 4 ||
                alarmSettingInfo?.repeat == 0) && (
                <Typography sx={{ paddingTop: "8px" }}>
                  {languageContext.words.month}
                </Typography>
              )}
              {(alarmSettingInfo?.repeat == 4 ||
                alarmSettingInfo?.repeat == 3 ||
                alarmSettingInfo?.repeat == 0) && (
                <TextField
                  name="day"
                  type="number"
                  onFocus={(e) => {
                    if (Number(alarmSettingInfo?.day) === 0) {
                      e.currentTarget.select();
                    }
                  }}
                  value={Number(alarmSettingInfo?.day).toFixed(0)}
                  onChange={onChangeValue}
                  inputProps={{
                    maxLength: 2,
                  }}
                  sx={{
                    input: {
                      height: "42px",
                      padding: "0px",
                      width: "60px",
                      "padding-left": "14px",
                    },
                  }}
                  error={inputError.day}
                ></TextField>
              )}
              {(alarmSettingInfo?.repeat == 4 ||
                alarmSettingInfo?.repeat == 3 ||
                alarmSettingInfo?.repeat == 0) && (
                <Typography sx={{ paddingTop: "8px" }}>
                  {languageContext.words.day}
                </Typography>
              )}
              <TextField
                name="hour"
                type="number"
                onFocus={(e) => {
                  if (Number(alarmSettingInfo?.hour) === 0) {
                    e.currentTarget.select();
                  }
                }}
                value={Number(alarmSettingInfo?.hour).toFixed(0)}
                onChange={onChangeValue}
                inputProps={{
                  maxLength: 2,
                }}
                sx={{
                  input: {
                    height: "42px",
                    padding: "0px",
                    width: "60px",
                    "padding-left": "14px",
                  },
                }}
                error={inputError.hour}
              ></TextField>
              <Typography sx={{ paddingTop: "8px" }}>
                {languageContext.words.time}
              </Typography>
              <TextField
                name="minute"
                type="number"
                onFocus={(e) => {
                  if (Number(alarmSettingInfo?.minute) === 0) {
                    e.currentTarget.select();
                  }
                }}
                value={Number(alarmSettingInfo?.minute).toFixed(0)}
                onChange={onChangeValue}
                inputProps={{
                  maxLength: 2,
                }}
                sx={{
                  input: {
                    height: "42px",
                    padding: "0px",
                    width: "60px",
                    "padding-left": "14px",
                  },
                }}
                error={inputError.minute}
              ></TextField>
              <Typography sx={{ paddingTop: "8px" }}>
                {languageContext.words.min}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <FormLabel required sx={{ paddingTop: "8px" }}>
              {languageContext.words.repeat_setting}
            </FormLabel>
            <RadioGroup
              name="repeat"
              row
              sx={{ paddingLeft: "15px" }}
              onChange={onChangeValue}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={languageContext.words.none}
                checked={alarmSettingInfo?.repeat == 0}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={languageContext.words.daily}
                checked={alarmSettingInfo?.repeat == 1}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={languageContext.words.weekly}
                checked={alarmSettingInfo?.repeat == 2}
              />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label={languageContext.words.end_of_month}
                checked={alarmSettingInfo?.repeat == 5}
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={languageContext.words.monthly}
                checked={alarmSettingInfo?.repeat == 3}
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label={languageContext.words.annually}
                checked={alarmSettingInfo?.repeat == 4}
              />
            </RadioGroup>
            <FormGroup row sx={{ paddingLeft: "15px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekSun"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.sun}
                value={alarmSettingInfo?.weekSun}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekSun == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekMon"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.mon}
                value={alarmSettingInfo?.weekMon}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekMon == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekTue"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.tue}
                value={alarmSettingInfo?.weekTue}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekTue == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekWeb"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.wed}
                value={alarmSettingInfo?.weekWeb}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekWeb == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekThu"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.thu}
                value={alarmSettingInfo?.weekThu}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekThu == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekFri"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.fri}
                value={alarmSettingInfo?.weekFri}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekFri == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="weekSat"
                    onChange={(e) => {
                      onChangeValue(e);
                      onChangeWeekCheckBox(e);
                    }}
                  />
                }
                label={languageContext.words.sat}
                value={alarmSettingInfo?.weekSat}
                checked={
                  alarmSettingInfo?.repeat == 2 &&
                  alarmSettingInfo?.weekSat == 1
                }
                disabled={alarmSettingInfo?.repeat != 2}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormLabel required>{languageContext.words.message}</FormLabel>
            </FormGroup>
            <TextField
              rows={1}
              value={alarmSettingInfo?.message}
              onChange={onChangeValue}
              name="message"
              inputProps={{ maxLength: 128 }}
              sx={{
                input: {
                  height: "42px",
                  padding: "0px",
                  width: "700px",
                  paddingLeft: "14px",
                },
              }}
              error={inputError.message}
            ></TextField>
          </Grid>
        </Grid>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetAlarmSettings;
