import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderG,
  StyledTableHeaderLG,
} from "../../styledComponents/styledTable";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
// import {
//   bitLimitCells,
//   bitLimmitReq,
//   // matomeArray,
// } from "../../../jepx/BiddingScreen/BiddingScreen_data";
import {
  bitLimitCells,
  bitLimmitReq,
} from "../../../../types/jepx/BiddingScreen";
import axios from "axios";
import { LanguageContext } from "../../localization/localization";
import { DateContext } from "../../customComponents/GlobalDatePicker";
import CustomModal from "../../customComponents/CustomModal";
import { format } from "date-fns";
import { TextField } from "@mui/material";
import { useAuthedApi } from "../../../../common/axios";
import CustomDialog, { ButtonType } from "../../customComponents/CustomDialog";
import { BUSINESS_MODEL } from "../../../../common/constant";

const times = [
  "00:00～00:30",
  "00:30～01:00",
  "01:00～01:30",
  "01:30～02:00",
  "02:00～02:30",
  "02:30～03:00",
  "03:00～03:30",
  "03:30～04:00",
  "04:00～04:30",
  "04:30～05:00",
  "05:00～05:30",
  "05:30～06:00",
  "06:00～06:30",
  "06:30～07:00",
  "07:00～07:30",
  "07:30～08:00",
  "08:00～08:30",
  "08:30～09:00",
  "09:00～09:30",
  "09:30～10:00",
  "10:00～10:30",
  "10:30～11:00",
  "11:00～11:30",
  "11:30～12:00",
  "12:00～12:30",
  "12:30～13:00",
  "13:00～13:30",
  "13:30～14:00",
  "14:00～14:30",
  "14:30～15:00",
  "15:00～15:30",
  "15:30～16:00",
  "16:00～16:30",
  "16:30～17:00",
  "17:00～17:30",
  "17:30～18:00",
  "18:00～18:30",
  "18:30～19:00",
  "19:00～19:30",
  "19:30～20:00",
  "20:00～20:30",
  "20:30～21:00",
  "21:00～21:30",
  "21:30～22:00",
  "22:00～22:30",
  "22:30～23:00",
  "23:00～23:30",
  "23:30～00:00",
];

const GET_BID_LIMIT = "/getBiddingScreenBidLimitAddress";
const POST_BID_LIMIT = "/postBiddingScreenBidLimitAddress";

//テーブルのヘッダーライトグレー
const HeaderCellLG = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//テーブルのヘッダーグレー
const HeaderCellG = styled(StyledTableHeaderG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
  }
`;

const BiddingScreenBidLimit = (props: any) => {
  const {
    hiddenUser,
    hiddenBusinessModel,
    selectedBusinessModel,
    setLimit,
    limit,
    areaId,
    isBidLimitDialogOpen,
    setIsBidLimitDialogOpen,
  } = props;
  const selectedDate = useContext(DateContext);
  const languageContext = useContext(LanguageContext);
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>(limit);
  const api = useAuthedApi();

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [digOpen, setDigOpen] = useState<boolean>(false);
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // 設定した制限値をDBに登録
  const postBidLimit = async () => {
    await api
      .post(POST_BID_LIMIT, {
        bidLimit: bidLimit,
        areaId: areaId,
      })
      .then((res) => {
        setDialogMessage(languageContext.words.saved);
        setDigOpen(true);
      }).catch((error) => {
        setDialogMessage(languageContext.words.saved_failed);
        setDigOpen(true);
      });
  };

  // 入札制限データ取得
  const getBidLimit = async () => {
    // 市場取引を利用するビジネスモデルの場合入札制限データを取得
    if(selectedBusinessModel === BUSINESS_MODEL.MARKET_TRAN ||
      selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN) {
      await api
        .get(GET_BID_LIMIT, {
          params: {
            areaId: areaId,
            targetDate: format(selectedDate.pickDate as Date, "yyyy-MM-dd"),
          },
        })
        .then((res) => {
          const bidLimit: bitLimmitReq = res.data.result;
          setLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  // upperPowerVolume: Number(item.upperPowerVolume).toFixed(2),
                  // lowerPowerVolume: Number(item.lowerPowerVolume).toFixed(2),
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
          setBidLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLimit({
        area: Number(areaId),
        dayLimitBit: new Array(2).fill(true).map((_, index) => {
          const data: bitLimitCells = {
            type: index + 1,
            cells: new Array(48).fill(true).map((_, index) => ({
              upperPowerPrice: "999.99",
              lowerPowerPrice: "0.00",
            })),
          };
          return data;
        }),
      });
    }
  };

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      // useEffectでDBから入札制限データを取ってくる
      getBidLimit();
    }
  }, []);

  return (
    <>
      <CustomModal
        hidden={hiddenUser}
        title={languageContext.words.spot_bidding_limit}
        open={isBidLimitDialogOpen}
        onAccept={async () => {
          await postBidLimit();
          await getBidLimit();
        }}
        onClose={async () => {
          setIsBidLimitDialogOpen(false);
          await getBidLimit();
        }}
        btnText={languageContext.words.save}
      >
        <TableRender
          setLimit={setBidLimit}
          limit={bidLimit}
          getBidLimit={getBidLimit}
          areaId={areaId}
        />
      </CustomModal>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

const TableRender = (props: any) => {
  const { setLimit, limit, getBidLimit, areaId } = props;

  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      getBidLimit();
    }
  }, []);

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    colIndex: number,
    rowIndex: number,
    limit: bitLimmitReq,
    setLimit: React.Dispatch<React.SetStateAction<bitLimmitReq>>
  ) => {
    const tempBitLimit: bitLimmitReq = Object.assign({}, limit);
    if (colIndex === 0 || colIndex === 2) {
      if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
        ) > 999.99
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = "999.99";
      } else if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
        ) < 0
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = "0.00";
      } else {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = Number(
          limit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
        ).toFixed(2);
      }
    } else {
      // } else if (Math.floor(colIndex / 2) % 2 === 0) {
      if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
        ) > 999.99
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = "999.99";
      } else if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
        ) < 0
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = "0.00";
      } else {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = Number(
          limit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
        ).toFixed(2);
      }
    }
    setLimit(tempBitLimit);
  };

  //テキストフィールドを打つとそこのステイトを変化させる関数
  const changeTfState = (
    inputedValue: string,
    colIndex: number,
    rowIndex: number
  ) => {
    //連想配列のコピーはObject.createでちゃんとしたコピーが可能
    const tempBitLimit: bitLimmitReq = Object.assign({}, limit);
    if (colIndex === 0 || colIndex === 2) {
      tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
        rowIndex
      ].lowerPowerPrice = inputedValue.replace(/-/g, "");
    } else {
      tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
        rowIndex
      ].upperPowerPrice = inputedValue.replace(/-/g, "");
    }
    setLimit(tempBitLimit);
  };

  return (
    <TableContainer sx={{ width: "630px", height: "633px" }}>
      <StyledTable stickyHeader={true}>
        <TableHead sx={{ display: "block", position: "sticky" }}>
          <TableRow>
            <HeaderCellLG rowSpan={3} align="center" sx={{ width: "100.5px" }}>
              {languageContext.words.timeB}
            </HeaderCellLG>
            <HeaderCellLG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.sell}
            </HeaderCellLG>
            <HeaderCellG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.buy}
            </HeaderCellG>
          </TableRow>

          <TableRow>
            <HeaderCellLG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.bid_price}
              <br />({languageContext.words.yen_kwh})
            </HeaderCellLG>
            <HeaderCellG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.bid_price}
              <br />({languageContext.words.yen_kwh})
            </HeaderCellG>
          </TableRow>
          <TableRow>
            <HeaderCellLG align="center" sx={{ width: "132px" }}>
              {languageContext.words.lower_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" sx={{ width: "132px" }}>
              {languageContext.words.upper_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" sx={{ width: "132px" }}>
              {languageContext.words.lower_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" sx={{ width: "132px" }}>
              {languageContext.words.upper_limit}
            </HeaderCellLG>
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            display: "block",
            overflowY: "auto",
            height: "500px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {Array(48)
            .fill(0)
            .map((row, rowIndex) => {
              return (
                <TableRow key={"bidLimitRow_" + rowIndex}>
                  <StyledTableCell
                    key={"bidLimitCol_time" + rowIndex}
                    sx={{ width: "100px", padding: "0px", height: "30px" }}
                    align="center"
                  >
                    {times[rowIndex]}
                  </StyledTableCell>
                  {Array(4)
                    .fill(0)
                    .map((col, colIndex) => {
                      return (
                        <StyledTableCell
                          key={"bidLimitCol_buy_min" + colIndex}
                          sx={{
                            width: colIndex === 3 ? "121px" : "131px",
                            padding: "0px",
                          }}
                          align="center"
                        >
                          <TextField
                            key={`bidLimitCol_${rowIndex}_${colIndex}`}
                            InputProps={{
                              inputProps: {
                                tabIndex: colIndex + 1,
                                min: "0",
                                max: "999.99",
                                step: "0.01",
                                style: {
                                  padding: "0px",
                                  width: "100px",
                                  height: "22px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  textAlign: "right",
                                },
                              },
                            }}
                            type="number"
                            value={
                              colIndex === 0 || colIndex === 2
                                ? limit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
                                    rowIndex
                                  ].lowerPowerPrice
                                : limit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
                                    rowIndex
                                  ].upperPowerPrice
                            }
                            onFocus={(e) => {
                              if (
                                (colIndex === 0 || colIndex === 2
                                  ? limit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                      .cells[rowIndex].lowerPowerPrice
                                  : limit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                      .cells[rowIndex].upperPowerPrice) ===
                                "0.00"
                              ) {
                                e.currentTarget.select();
                              }
                            }}
                            onBlur={(e) => {
                              processText(colIndex, rowIndex, limit, setLimit);
                            }}
                            onKeyDown={(event) => {
                              if (
                                event.key === "e" ||
                                event.key === "E" ||
                                event.key === "-"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              changeTfState(e.target.value, colIndex, rowIndex);
                            }}
                          />
                        </StyledTableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default BiddingScreenBidLimit;
