import React, { useState, useEffect, useContext } from "react";
import "./css/SetSupplyBgList.css";
import { FormGroup, FormLabel, Stack } from "@mui/material";
import { LanguageContext } from "../common/localization/localization";
import {
  bpInfo,
  resBpInfo,
  setDemandBGInfo,
} from "../../types/master/SupplyBgList";
import { modalMode } from "./SupplyBgList";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL, RESULT_CODE } from "../../common/constant";
import { CommonResponse } from "../../types/common/Api";
import { FormatAlignCenter } from "@mui/icons-material";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

type powerBpInfo = {
  id: number;
  bpid: number;
  name: string;
  code: string;
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bgName: boolean;
  bgCode: boolean;

  areaId: boolean;
  bp_id: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bgName: false,
  bgCode: false,

  areaId: false,
  bp_id: false,
};

//引数で編集か新規追加かを判断。編集の場合、書かれている情報も持ってくる
const SetSupplyBgList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  bgIDForEdit: number
) => {
  //初期化用
  const initialDemandBGInfo: setDemandBGInfo = {
    bgId: 0,
    bgName: "",
    bgCode: "",
    bp_id: 0,
    areaId: "00",
    remarks: "",
  };

  const [demandBGInfo, setdemandBGInfo] =
    useState<setDemandBGInfo>(initialDemandBGInfo);

  // 通信エラー
  const api = useAuthedApi();

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる

  const { areaOptions } = useAreaUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // エリアマスタデータのref
  const [areaInfos, setareaInfos] = useState<string[]>(["00"]);

  // 事業者情報
  const [powerBpInfo, setPowerBpInfo] = useState<bpInfo>();

  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ

  const setAreaId = (id: number) => {
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setDemandBGInfo = Object.assign({}, demandBGInfo);
    tmpAreaInfo.areaId = areaIdString;

    setdemandBGInfo(tmpAreaInfo);
  };

  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        (async () => {
          Promise.all([
            getDataLinkedBgCode(bgIDForEdit),
            getSettingInfo(bgIDForEdit),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        (async () => {
          getSettingInfo(bgIDForEdit).finally(() => {
            setIsLoading(false);
          });
        })();
      }
    } else {
      setareaInfos(["00"]);
      setdemandBGInfo(initialDemandBGInfo);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化

  // 設定情報（事業者名、事業者コード、選択可能なエリアプルダウンの一覧）の取得
  const getSettingInfo = (bgId: number) => {
    return api
      .get<CommonResponse<resBpInfo>>(API_URL.GET_BG_SETTING_INFO, {
        params: { bgId },
      })
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_BP_DATA) {
          setDialogMessage(languageContext.words.setting_can_not_display_bp);
          setDigOpen(true);
        }

        if (res.data.resultCode === RESULT_CODE.NO_AREA) {
          setDialogMessage(languageContext.words.setting_can_not_select_area);
          setDigOpen(true);
        }

        const { bpId, bpName, bpCode, areaIds } = res.data.result;
        const temp: bpInfo = {
          bpId: bpId,
          bpName: bpName,
          bpCode: bpCode,
        };
        setPowerBpInfo(temp);
        setareaInfos(areaIds);
      });
  };

  // 需要BGデータ一行分取得
  const getDataLinkedBgCode = (bgId: number) => {
    let responseData: setDemandBGInfo;
    return api
      .get<CommonResponse<setDemandBGInfo>>(API_URL.GET_ONE_DEMANDBGDATA, {
        params: { bgId },
      })
      .then((response) => {
        editflg.current = true;
        responseData = response.data.result as setDemandBGInfo;
        setdemandBGInfo(responseData);
      })
      .catch((err) => {
        console.log(err);
        setdemandBGInfo(initialDemandBGInfo);
      });
  };

  const onChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpPowerBGInfo: setDemandBGInfo = Object.assign({}, demandBGInfo);
    switch (name) {
      case "bgName":
        tmpPowerBGInfo.bgName = value;
        break;
      case "bgCode":
        tmpPowerBGInfo.bgCode = value;
        break;
      case "remarks":
        tmpPowerBGInfo.remarks = value;
        break;
      default:
        break;
    }
    setdemandBGInfo(tmpPowerBGInfo);
  };

  //セーブして内容描画して閉じる
  const DoModeJob = () => {
    if (mode === modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // BG新規追加
  const AddDatas = () => {
    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      const reqBody = demandBGInfo;
      reqBody.bp_id = powerBpInfo?.bpId ?? 0;
      setIsLoading(true);
      api
        .post<CommonResponse<undefined>>(API_URL.SET_DEMANDBG_LIST_API, reqBody)
        .then((response) => {
          setIsLoading(false);
          if (response.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.demand_bg_duplication_message
            );
            setDigOpen(true);
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_bg_added);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.demand_bg_failed_add);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  };

  // BG更新
  const UpdateDatas = () => {
    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      setIsLoading(true);
      api
        .post<CommonResponse<undefined>>(
          API_URL.UPDATE_DEMANDBG_INFO,
          demandBGInfo
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.demand_bg_duplication_message
            );
            setDigOpen(true);
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_bg_update);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.demand_bg_failed_update);
          setDigOpen(true);
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (demandBGInfo?.areaId === "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (demandBGInfo?.bgCode === "") {
      error = true;
      tempInputErrors.bgCode = true;
    }
    if (demandBGInfo?.bgName === "") {
      error = true;
      tempInputErrors.bgName = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // BG削除(論理削除)
  const Delete = async (bgId: number | undefined) => {
    // 削除API呼出
    setIsLoading(true);
    await api
      .post<CommonResponse<undefined>>(API_URL.DELETE_DEMANDBG_INFO, {
        bgId: bgId,
      })
      .then((response) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(languageContext.words.demand_bg_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.demand_bg_failed_delete);
        setDigOpen(true);
        setIsLoading(false);
      });

    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  return (
    <>
      <CustomSettingModal
        title={languageContext.words.demand_bg_setting}
        open={toOpen}
        onAcceptLeft={() => DoModeJob()}
        onAcceptRight={() => Delete(demandBGInfo?.bgId)}
        onClose={() => HaldleClose()}
        btnTextLeft={languageContext.words.registration}
        btnTextRight={languageContext.words.delete}
        btnType={
          mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
        }
        isAcceptedKey={isLoading}
      >
        <Stack sx={{ height: "450px", width: "550px" }}>
          <CustomBoxBig>
            <FormLabel required>
              {languageContext.words.demand_bg_name}
            </FormLabel>
            <CustomTextFieldBig
              type="text"
              value={demandBGInfo?.bgName}
              onChange={onChangeValue}
              name="bgName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bgName}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.demand_bg_code}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={demandBGInfo?.bgCode}
              onChange={onChangeValue}
              name="bgCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.bgCode}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_bp_name}
              </CustomModalTypography>
              <CustomTextFieldSmall
                value={powerBpInfo?.bpName}
                error={inputError.bp_id}
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_bp_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerBpInfo?.bpCode}
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall></CustomBoxSmall>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>{languageContext.words.area}</FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                name="areaId"
                label={languageContext.words.area_unselected}
                value={demandBGInfo?.areaId}
                options={areaOptions(areaInfos, true)}
                onChange={(e: number) => {
                  setAreaId(e);
                }}
                error={inputError.areaId}
                disabled={mode === modalMode.editMode}
              />
            </CustomBoxSmall>
          </Stack>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={demandBGInfo?.remarks}
              onChange={onChangeValue}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Stack>
        <CustomModalProgress open={isLoading} />
      </CustomSettingModal>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default SetSupplyBgList;
