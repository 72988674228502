// 事業者一覧
import React, { useContext, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import "./css/UserList.css";
import Box from "@mui/material/Box";
import SetPowerBp from "./SetPowerBp";
import { LanguageContext } from "../common/localization/localization";
import { powerBpInfo } from "../../types/master/PowerBp";
import { Typography } from "@mui/material";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CommonResponse } from "../../types/common/Api";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export enum modalMode {
  editMode,
  addMode,
}

const PowerBp = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト

  const { role } = useContext(RoleContext);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);
  // 通信エラー
  const api = useAuthedApi();

  const areaNames = [
    languageContext.words.hokkaido_region,
    languageContext.words.tohoku_region,
    languageContext.words.tokyo_region,
    languageContext.words.chubu_region,
    languageContext.words.hokuriku_region,
    languageContext.words.kansai_region,
    languageContext.words.chugoku_region,
    languageContext.words.shikoku_region,
    languageContext.words.kyushu_region,
    languageContext.words.okinawa_region,
  ];

  const [show, setShow] = useState(false);
  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  const [powerBp, setPowerBp] = useState<powerBpInfo[]>();
  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行のBGID情報
  const [bpIdPK, setBpIdPK] = useState<number>(0);

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  // 初期描画
  useEffect(() => {
    (async () => {
      Promise.all([checkEditRoll(), getPowerBp()]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  // 設定モーダルが閉じたとき
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show) return;

    (async () => {
      setIsLoading(true);
      getPowerBp().finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show]);

  // 発電所一覧取得
  function getPowerBp() {
    return api
      .get<undefined, AxiosResponse<CommonResponse<powerBpInfo[]>>>(
        API_URL.GET_BP
      )
      .then((res) => {
        setPowerBp(res.data.result);
      })
      .catch((err) => {
        setPowerBp([]);
      });
  }

  // 編集可能ユーザか確認
  function checkEditRoll() {
    return api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: role } })
      .then((res) => {
        setEnableEdit(res.data);
      });
  }

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setBpIdPK(0);

    setShow(true);
  };

  // 管理エリアフラグから文字に変換
  function ConvertAreaFlagToAreaName(target: boolean[]) {
    let areaName = "";
    target.forEach((element, index) => {
      if (element) {
        areaName = areaName + areaNames[index] + ",";
      }
    });

    if (areaName.length != 0) {
      areaName = areaName.slice(0, -1);
    }

    return areaName;
  }

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (bpId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setBpIdPK(bpId);

    setShow(true);
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ height: "40px" }}>
          {enableEdit && (
            <NewRegistrationButton
              variant="outlined"
              onClick={() => HandleOpenForAddNew()}
              disabled={Boolean(powerBp?.length)}
            >
              {languageContext.words.new_registration}
            </NewRegistrationButton>
          )}
        </Box>
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.power_bp_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.power_bp_code}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig>
                {languageContext.words.target_area}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {powerBp?.map((bp) => (
              <TableRow key={bp.bpName}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={bp.bpName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {bp.bpName}
                    </Typography>
                    {enableEdit && (
                      <ModalButton
                        variant="outlined"
                        onClick={() => HandleOpenForEdit(bp.bpId)}
                      >
                        {languageContext.words.edit}
                      </ModalButton>
                    )}
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell title={bp.bpCode}>
                  {bp.bpCode}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={ConvertAreaFlagToAreaName(bp.targetArea)}
                >
                  {ConvertAreaFlagToAreaName(bp.targetArea)}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
      {SetPowerBp(show, HandleClose, mode, bpIdPK)}
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

export default PowerBp;
