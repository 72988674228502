import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./css/UserList.css";

// ユーザ一覧
import SetUserList from "./SetUserList";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Input,
  Button,
  Snackbar,
  Alert,
  FormHelperText,
  FormLabel,
} from "@mui/material";

import { StyledTableCell } from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { addDays, format, subDays } from "date-fns";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import SaveDataButton from "../common/customComponents/SaveDataButton";
import { reqAreaList } from "../../types/common/Search";
import { useAreaUtility } from "../../common/area";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";
import { AreaContext } from "../common/customComponents/CustomAreaContext";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
const AREA_LIST_URL = "/getAreaNameList";

export interface certifiCate {
  startDate: string;
  endDate: string;
  password: string;
  certificateFile: string;
  fileName: string;
  areaId?: string;
}

export const StyledButton = styled(Button)`
  && {
    min-height: 24px;
    width: auto;
    color: white;
    font-size: 13px;
    padding 6px 16px;
  }
`;

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  occtoFile: boolean;
  occtoPassword: boolean;
  generalFile: boolean;
  generalPassword: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  occtoFile: false,
  occtoPassword: false,
  generalFile: false,
  generalPassword: false,
};

export enum FileKind { //どっちのファイルを送るかを規定
  occtoFileNo,
  generalFileNo,
}

const safePasswordStr = /^[0-9a-zA-Z]*$/;

const CertificateList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // ビジネスモデルコンテキスト
  const { businessModel } = useContext(BusinessModelContext);

  // エリアコンテキスト
  const areaContext = useContext(AreaContext);

  // 通信エラー
  const api = useAuthedApi();

  //セレクトタブに表示するデータ
  const [areaList, setAreaNameList] = useState<string[]>([]);
  const { areaOptions } = useAreaUtility();

  // レンダリング管理
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  // アスペクト比
  const options = {
    maintainAspectRatio: false,
  };

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  const [areaId, setAreaId] = useState<string>("01");

  const [occtoStartDate, setOcctoStartDate] = useState<Date>(new Date());
  const [occtoEndDate, setOcctoEndDate] = useState<Date>(
    addDays(new Date(), 1)
  );
  const [occtoPassword, setOcctoPassword] = useState<string>("");

  const [generalStartDate, setGeneralStartDate] = useState<Date>(new Date());
  const [generalEndDate, setGeneralEndDate] = useState<Date>(
    addDays(new Date(), 1)
  );
  const [generalPassword, setGeneralPassword] = useState<string>("");

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  type Props = {
    show: boolean;
  };

  const StyledTableHeaderLG = styled(TableCell)(() => ({
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  }));

  const [occtoFile, setOcctoFile] = useState<File | null>(null);
  const [generalFile, setGeneralFile] = useState<File | null>(null);
  const [occtoFileName, setOcctoFileName] = useState<string>("");
  const [generalFileName, setGeneralFileName] = useState<string>("");

  //const [dialogMessage, setDialogMessage] = useState("");

  const inputOcctoRef = React.useRef<any>(null);
  const inputAreaRef = React.useRef<any>(null);

  // 証明書情報保存(ファイルアップデート込み)
  const onPostForm = (
    fileKind: FileKind,
    startDate: Date,
    endDate: Date,
    password: string,
    file: File | null,
    url: string,
    areaId?: string
  ) => {
    const error = isCorrectInfo(fileKind);

    if (file === null || error === true) {
      setDialogMessage(
        languageContext.words.certificate_failed_regidter_check_enter
      );
      setDigOpen(true);
      return;
    }
    // return;
    //}
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const val = String(reader.result);
      const fileName = file.name;
      const certificateData: certifiCate = {
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        certificateFile: val,
        fileName: fileName,
        password: password,
        areaId: areaId,
      };
      setIsLoaded(true);
      api
        .post(url, {
          certificateData,
        })
        .then((response) => {
          // 成功時
          setDialogMessage(languageContext.words.certificate_register);
          setDigOpen(true);
          setIsLoaded(false);
        })
        .catch((error) => {
          // エラー時
          console.log(error);
          setDialogMessage(languageContext.words.certificate_failed_regidter);
          setDigOpen(true);
        });
    };

  };

  const isCorrectInfo = (fileKind: FileKind): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, inputError);
    if (fileKind === FileKind.occtoFileNo) {
      tempInputErrors.occtoFile = false;
      tempInputErrors.occtoPassword = false;
      if (occtoFile == null) {
        error = true;
        tempInputErrors.occtoFile = true;
      }
      if (occtoPassword == "" || !safePasswordStr.test(occtoPassword)) {
        error = true;
        tempInputErrors.occtoPassword = true;
      }
    } else {
      tempInputErrors.generalFile = false;
      tempInputErrors.generalPassword = false;
      if (generalFile == null) {
        error = true;
        tempInputErrors.generalFile = true;
      }
      if (generalPassword == "" || !safePasswordStr.test(generalPassword)) {
        error = true;
        tempInputErrors.generalPassword = true;
      }
    }

    setInputError(tempInputErrors);
    return error;
  };

  React.useEffect(() => {
    // エリアセット
    initAreaList();
    (async () => {
      Promise.all([checkEditRoll(), getOcctoCertificate()]).finally(() => {
        setIsLoaded(false);
      });
    })();
  }, []);

  // エリアプルダウン初期表示
  const initAreaList = () => {
    const initArea = areaContext.areaId;
    setAreaNameList(businessModel.map((v) => v.areaId));

    if (businessModel.length !== 0 && initArea === "00") {
      //ビジネスモデルが設定されているかつエリアコンテキストがセットされていない
      setAreaId(businessModel[0].areaId);
    } else {
      setAreaId(initArea);
    }
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // OCCTO証明書データ取得
  const getOcctoCertificate = () => {
    api
      .get(API_URL.OCCTO_GET)
      .then((res) => {
        const certificateData: certifiCate = res.data[0];
        setOcctoStartDate(
          certificateData.startDate !== null
            ? new Date(certificateData.startDate)
            : new Date()
        );
        setOcctoEndDate(
          certificateData.endDate !== null
            ? new Date(certificateData.endDate)
            : addDays(new Date(), 1)
        );
        setOcctoFileName(
          certificateData.fileName !== null
            ? certificateData.fileName.split("/").pop() as string
            : ""
        );
        setOcctoPassword(
          certificateData.password !== null
            ? certificateData.password
            : ""
        );
      })
      .catch((err) => console.log(err));
  };

  // 一般送配電証明書データ取得
  //エリアIDが変更されるたびに表示データ再取得
  useEffect(() => {
    // getArea();
    getGeneralCertificate();
  }, [areaId]);

  const getGeneralCertificate = () => {
    api
      .get(API_URL.GENERAL_GET, { params: { areaId: areaId } })
      .then((res) => {
        console.log(res.data);
        if (res.data[0] === undefined) {
          setGeneralFile(null);
          setGeneralFileName("");
          setGeneralStartDate(new Date());
          setGeneralEndDate(addDays(new Date(), 1));
          setGeneralPassword("");
        } else {
          const certificateData: certifiCate = res.data[0];
          setGeneralStartDate(new Date(certificateData.startDate));
          setGeneralEndDate(new Date(certificateData.endDate));
          setGeneralFileName(
            certificateData.fileName.split("/").pop() as string
          );
          setGeneralPassword(certificateData.password);
          //changeArea(Number(certificateData.areaId))
        }
      })
      .catch((err) => console.log(err));
  };

  // 一般送配電のエリア変更時
  const changeArea = (selectItem: number) => {
    setAreaId(selectItem.toString().padStart(2, "0"));
  };

  // ファイル選択時
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    // ファイル選択がキャンセルされた時は undefined
    const file = e.target.files !== null ? e.target.files[0] : null;
    setFile(() => {
      return file ? file : null;
    });

  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <div className="cn-setting-display">
      <Box sx={{ width: "1300px" }}>
        <fieldset>
          <legend>
            {languageContext.words.certificate_wide_area_agencies}
          </legend>
          <TableContainer>
            {/* 広域機関用証明書 */}
            <Table
              sx={{
                tableLayout: "fixed",
                overflowX: "unset",
                width: "fit-content !important",
                padding: "10px 20px",
                marginBottom: "16px",
              }}
              aria-label="userList"
            >
              <TableHead
                sx={{
                  background: "#F0F0F0",
                  position: "sticky",
                }}
              >
                <TableRow>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "220px" }}
                  >
                    {languageContext.words.effective_date_start_date}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "220px" }}
                  >
                    {languageContext.words.expiration_date}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "440px" }}
                  >
                    <FormLabel required>
                      {languageContext.words.password}
                    </FormLabel>
                    <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                      {languageContext.words.setting_available_characters_5}
                    </FormHelperText>
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "440px" }}
                  >
                    <FormLabel required>
                      {languageContext.words.client_certificate}
                    </FormLabel>
                  </StyledTableHeaderLG>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "0px",

                      textAlign: "center",
                    }}
                  >
                    <CustomDatePickerTryal
                      enableEdit={enableEdit}
                      setValue={setOcctoStartDate}
                      value={occtoStartDate}
                      maxDate={subDays(occtoEndDate, 1)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "0px",

                      textAlign: "center",
                    }}
                  >
                    <CustomDatePickerTryal
                      enableEdit={enableEdit}
                      setValue={setOcctoEndDate}
                      value={occtoEndDate}
                      minDate={addDays(occtoStartDate, 1)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "42px", padding: "0px" }}
                  >
                    <TextField
                      disabled={!enableEdit ? true : false}
                      sx={{ width: "94%" }}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          maxLength: 127,
                          style: {
                            padding: 1,
                            height: "30px",
                            textAlign: "left",
                          },
                        },
                      }}
                      value={occtoPassword}
                      onChange={(e) => {
                        setOcctoPassword(e.target.value);
                      }}
                      error={inputError.occtoPassword}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "8px",

                      display: "flex",
                    }}
                  >
                    <Button
                      disabled={!enableEdit ? true : false}
                      variant="outlined"
                      sx={{
                        //border: "1px solid lightblue",
                        margin: "2px",
                        width: "170px",
                      }}
                      onClick={() => inputOcctoRef.current.click()}
                      color={
                        inputError.occtoFile === true ? "error" : "primary"
                      }
                    >
                      {languageContext.words.file_select}
                    </Button>
                    <Typography
                      sx={{
                        borderBottom: "1px solid black",
                        margin: "2px",
                        width: "100%",
                        marginTop: "9px",
                      }}
                    >
                      {occtoFileName}
                    </Typography>
                    <Input
                      sx={{ width: "0px", visibility: "hidden" }}
                      id="file1"
                      name="file1"
                      inputProps={{
                        style: {
                          width: "0px",
                          padding: 1,
                          height: "30px",
                          textAlign: "right",
                          marginRight: "2px",
                          backgroundColor: "lightgray",
                          visibility: "hidden",
                        },
                      }}
                      // hidden={true}
                      inputRef={inputOcctoRef}
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeHandler(e, setOcctoFile);
                        setOcctoFileName(
                          e.target.files === null ? "" : e.target.files[0].name
                        );
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* 登録情報 */}
          </TableContainer>
          <Box
            sx={{
              width: "4%",
              height: "50%",
              display: "flex",
              paddingBottom: "6px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
          >
            <SaveDataButton
              sx={{ height: "40px" }}
              hidden={!enableEdit}
              onAccept={() =>
                onPostForm(
                  FileKind.occtoFileNo,
                  occtoStartDate,
                  occtoEndDate,
                  occtoPassword,
                  occtoFile,
                  API_URL.OCCTO_POST
                )
              }
            />
          </Box>
        </fieldset>
      </Box>
      <Box sx={{ width: "1520px" }}>
        <fieldset>
          <legend>
            {
              languageContext.words
                .certificate_general_transmission_distribution
            }
          </legend>
          <TableContainer>
            {/* 一般送配電用証明書 */}
            <Table
              sx={{
                tableLayout: "fixed",
                overflowX: "unset",
                width: "fit-content !important",
                padding: "10px 20px",
                marginBottom: "16px",
              }}
              aria-label="userList"
            >
              <TableHead
                sx={{
                  background: "#F0F0F0",
                  position: "sticky",
                }}
              >
                <TableRow>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "220px" }}
                  >
                    <FormLabel required>{languageContext.words.area}</FormLabel>
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "220px" }}
                  >
                    {languageContext.words.effective_date_start_date}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "220px" }}
                  >
                    {languageContext.words.expiration_date}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "440px" }}
                  >
                    <FormLabel required>
                      {languageContext.words.password}
                    </FormLabel>
                    <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                      {languageContext.words.setting_available_characters_5}
                    </FormHelperText>
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG
                    style={{
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                    align="center"
                    sx={{ height: "42px", padding: "0px", width: "440px" }}
                  >
                    <FormLabel required>
                      {languageContext.words.client_certificate}
                    </FormLabel>
                  </StyledTableHeaderLG>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                    align="left"
                    sx={{
                      padding: "0px",
                      height: "42px",
                      paddingLeft: "31px",
                      textAlign: "left",
                    }}
                  >
                    <CustomSelectList
                      label={"areaName"}
                      value={areaId}
                      options={areaOptions(areaList, false)}
                      onChange={changeArea}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    <CustomDatePickerTryal
                      enableEdit={enableEdit}
                      setValue={setGeneralStartDate}
                      value={generalStartDate}
                      maxDate={subDays(generalEndDate, 1)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    <CustomDatePickerTryal
                      enableEdit={enableEdit}
                      setValue={setGeneralEndDate}
                      value={generalEndDate}
                      minDate={addDays(generalStartDate, 1)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "0px",
                      textAlign: "center",
                    }}
                  >
                    <TextField
                      disabled={!enableEdit ? true : false}
                      sx={{ width: "94%" }}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          maxLength: 127,
                          style: {
                            padding: 1,
                            height: "30px",
                            textAlign: "left",
                            marginRight: "2px",
                          },
                        },
                      }}
                      value={generalPassword}
                      onChange={(e) => {
                        setGeneralPassword(e.target.value);
                      }}
                      error={inputError.generalPassword}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      height: "42px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <Button
                      disabled={!enableEdit ? true : false}
                      variant="outlined"
                      sx={{
                        //border: "1px solid lightblue",
                        margin: "2px",
                        width: "170px",
                      }}
                      onClick={() => inputAreaRef.current.click()}
                      color={
                        inputError.generalFile === true ? "error" : "primary"
                      }
                    >
                      {languageContext.words.file_select}
                    </Button>
                    <Typography
                      sx={{
                        borderBottom: "1px solid black",
                        margin: "2px",
                        width: "100%",
                        marginTop: "9px",
                      }}
                    >
                      {generalFileName}
                    </Typography>
                    <Input
                      sx={{ width: "0px", visibility: "hidden" }}
                      id="file1"
                      name="file1"
                      value=""
                      inputProps={{
                        style: {
                          width: "0px",
                          padding: 1,
                          height: "30px",
                          textAlign: "right",
                          // marginRight: "2px",
                          backgroundColor: "lightgray",
                          visibility: "hidden",
                        },
                      }}
                      // hidden={true}
                      inputRef={inputAreaRef}
                      onClick={() => {
                        inputAreaRef.current.value = "";
                      }}
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeHandler(e, setGeneralFile);
                        setGeneralFileName(
                          e.target.files === null ? "" : e.target.files[0].name
                        );
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* 登録情報 */}
          </TableContainer>
          <Box
            sx={{
              width: "4%",
              height: "50%",
              // display: "flex",
              paddingBottom: "6px",
              marginLeft: "auto",
              // marginRight: "8%",
            }}
          >
            <SaveDataButton
              sx={{ height: "40px" }}
              hidden={!enableEdit}
              onAccept={() =>
                onPostForm(
                  FileKind.generalFileNo,
                  generalStartDate,
                  generalEndDate,
                  generalPassword,
                  generalFile,
                  API_URL.GENERAL_POST,
                  areaId
                )
              }
            />
          </Box>
        </fieldset>
      </Box>
      <CustomModalProgress open={isLoaded} />
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

export default CertificateList;
