import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import "./css/UserList.css";

// 発電所一覧
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import SetPowerPlantList from "./SetPowerPlantList";
import SetPowerLimit from "./SetPowerLimit";
import SetStorageBattery from "./SetStorageBattery";
import { LanguageContext } from "../common/localization/localization";
import {
  powerPlantListInfo,
  resPowerPlantListInfo,
} from "../../types/master/PowerPlantList";
import { Typography } from "@mui/material";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { usePowerSupplyTypeUtility } from "../../common/powerSupplyType";
import { useAreaUtility } from "../../common/area";
import { usePowerPlantUtility } from "../../common/powerEnergy";
import { useExistTypeUtility } from "../../common/batteryExist";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { StyledButton } from "../common/styledComponents/styledButton";
import { CommonResponse } from "../../types/common/Api";
import SetOwnConsumption from "./SetOwnCosumption";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export enum modalMode {
  editMode,
  addMode,
}

//発電所種別
type powerPlantKind = {
  name: string; //種類
  id: number; //番号
};

//発電所種別
type generateWayKind = {
  name: string; //種類
  id: number; //番号
};

const PowerPlantList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  //電源種別IDからnameに変換
  const { convertIdToSupplyTypeName } = usePowerSupplyTypeUtility();
  //エリアIDからエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();
  //発電方式IDを発電方式名に変換
  const { convertIdToEnergyName } = usePowerPlantUtility();
  //蓄電池有無フラグから
  const { convertIdToBatteryExistName } = useExistTypeUtility();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  // 設定モーダル管理
  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);

  const [show, setShow] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [showOwnConsumption, setShowOwnConsumption] = useState(false);
  const [showStorage, setShowStorage] = useState(false);
  const [powerPlant, setPowerPlant] = useState<powerPlantListInfo[]>([]);
  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行のPK(powerId)情報
  const [powerIdPK, setPowerIdPK] = useState<number>(0);
  const [contractPower, setContractPower] = useState<number>(0);

  //編集ボタンを押した行のエリアID情報
  const [areaId, setAreaId] = useState<string>("");

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  // 設定モーダル開く
  const HandleOpenLimit = () => setShowLimit(true);
  // 設定モーダル閉じる
  const HandleCloseLimit = () => setShowLimit(false);

  // 設定モーダル閉じる
  const HandleCloseStorage = () => setShowStorage(false);

  // 初期表示
  useEffect(() => {
    (async () => {
      Promise.all([checkEditRoll(), getPowerPlant()]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  // 表示（現状モーダル表示を管理するshowがfalseになったタイミングで描写）
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show || showStorage || showLimit || showOwnConsumption) return;
    setIsLoading(true);
    (async () => {
      getPowerPlant().finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show, showStorage, showLimit, showOwnConsumption]);

  // 発電所一覧取得
  function getPowerPlant() {
    return api
      .get<CommonResponse<resPowerPlantListInfo>>(API_URL.POWERPLANT_LIST)
      .then((res) => {
        setPowerPlant(res.data.result);
      })
      .catch((err) => {
        setPowerPlant([]);
      });
  }

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((res) => {
        setEnableEdit(res.data);
      });
  };

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setPowerIdPK(0);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (powerId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setPowerIdPK(powerId);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const OpenLimitModal = (powerId: number) => {
    // //編集モードに
    // setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setPowerIdPK(powerId);

    setShowLimit(true);
  };

  //蓄電池設定モーダルを編集モードで開く
  const OpenStorageBatteryModal = (powerId: number, contractPower: number) => {
    setPowerIdPK(powerId);
    setContractPower(Number(contractPower));
    setShowStorage(true);
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ height: "40px" }}>
          {enableEdit && (
            <NewRegistrationButton
              variant="outlined"
              onClick={() => HandleOpenForAddNew()}
            >
              {languageContext.words.new_registration}
            </NewRegistrationButton>
          )}
        </Box>
      </Box>
      {SetPowerPlantList(show, HandleClose, mode, powerIdPK)}
      {SetPowerLimit(showLimit, HandleCloseLimit, powerIdPK)}
      {SetStorageBattery(
        showStorage,
        HandleCloseStorage,
        powerIdPK,
        contractPower
      )}
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig sx={{ width: "600px !important" }}>
                {languageContext.words.power_plant_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.area}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.power_supply_type}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.power_gen_method}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig>
                {languageContext.words.affiliation_power_bg}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.contracted_power}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.storage_battery_ownership}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.storage_battery_setting_button}
              </StyledTableHeaderSmall>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {powerPlant?.map((v) => (
              <TableRow key={v.powerId}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={v.powerName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {v.powerName}
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {enableEdit && (
                        <ModalButton
                          variant="outlined"
                          onClick={() => HandleOpenForEdit(v.powerId)}
                        >
                          {languageContext.words.edit}
                        </ModalButton>
                      )}
                      <ModalButton
                        variant="outlined"
                        onClick={() => OpenLimitModal(v.powerId)}
                      >
                        {languageContext.words.threshold_setting}
                      </ModalButton>
                      <ModalButton
                        variant="outlined"
                        onClick={() => {
                          setPowerIdPK(v.powerId);
                          setShowOwnConsumption(true);
                        }}
                      >
                        {languageContext.words.own_consumption_setting_edit}
                      </ModalButton>
                    </Box>
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToAreaName(v.areaId)}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToSupplyTypeName(v.powerKind)}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToEnergyName(v.energyKind.toString())}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  sx={{ textAlign: "left !important" }}
                  title={v.powerBgName}
                >
                  {v.powerBgName}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {Number(v.contrantPower).toLocaleString()}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToBatteryExistName(v.batteryFlag.toString())}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {enableEdit && (
                    <ModalButton
                      variant="outlined"
                      onClick={() =>
                        OpenStorageBatteryModal(v.powerId, v.contrantPower)
                      }
                    >
                      {languageContext.words.edit}
                    </ModalButton>
                  )}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
      <SetOwnConsumption
        open={showOwnConsumption}
        powerId={powerIdPK}
        HandleClose={() => {
          setShowOwnConsumption(false);
        }}
      />
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

export default PowerPlantList;
