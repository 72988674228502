import React, { useState, useEffect, useContext } from "react";
import "./css/SetUserList.css";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./UserList";
import { setUserInfo, bpArea } from "../../types/master/SetUserList";

import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { CustomTextFieldBig } from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useRoleUtility } from "../../common/role";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { AREA_ID } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

type role = {
  role_id: string;
  role_nm: string;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  userId: boolean;
  userName: boolean;
  mailAddress: boolean;
  roleId: boolean;
  password: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  userId: false,
  userName: false,
  mailAddress: false,
  roleId: false,
  password: false,
};

//引数で編集/新規登録 を判断する。編集の場合情報もらう
const SetUserList = (
  toOpen: boolean,
  HandleClose: () => void,
  mode: modalMode,
  userIdForEdit: string
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  //通信エラー
  const api = useAuthedApi();

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const roleContext = useContext(RoleContext);

  const { roleOptions } = useRoleUtility();

  const [isPassDigOpen, setIsPassDigOpen] = useState(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [areaList, setAreaList] = useState<bpArea[]>([]);
  const [userListInfo, setUserInfo] = useState<setUserInfo>();
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  let isClosing = false;
  // 初期化用
  const initializeUserListInfo: setUserInfo = {
    userId: "", // ユーザID
    userName: "", // ユーザー名称
    mailAddress: "", // メールアドレス
    roleId: "", // ロール
    password: "", // パスワード
    confirmPassword: "", // 確認用パスワード
    targetArea01: 0, // 北海道エリア担当
    targetArea02: 0, // 東北エリア担当
    targetArea03: 0, // 東京エリア担当
    targetArea04: 0, // 中部エリア担当
    targetArea05: 0, // 北陸エリア担当
    targetArea06: 0, // 関西エリア担当
    targetArea07: 0, // 四国エリア担当
    targetArea08: 0, // 中国エリア担当
    targetArea09: 0, // 九州エリア担当
    targetArea10: 0, // 沖縄エリア担当
    remarks: "",
  };

  //初期化する
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      getBpAreaList().finally(() => {
        setIsLoading(false);
      });
    })();
    setUserInfo(initializeUserListInfo);
  }, []);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化

  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      isClosing = false;
      if (mode === modalMode.editMode) {
        (async () => {
          //1レコード取得
          Promise.all([getroll(), getRecordUserList(userIdForEdit)]).finally(
            () => {
              setIsLoading(false);
            }
          );
        })();
      } else {
        setIsPassDigOpen(true);
        (async () => {
          getroll().finally(() => {
            setIsLoading(false);
          });
        })();
      }
    } else {
      // 開閉で必ず更新する
      setUserInfo({ ...initializeUserListInfo });
      setInputError(initErrorInput);
      setIsPassDigOpen(false);
      isClosing = true;
    }
    console.log("開閉状態" + toOpen);
  }, [toOpen]);

  /**
   * 発電事業者の担当エリアを取得
   */
  const getBpAreaList = () => {
    return api
      .get(API_URL.GET_AREA_LIST)
      .then((response) => {
        const resAreaList: bpArea[] = response.data.map((v: any) => {
          return {
            area01_fg: v.area01_fg,
            area02_fg: v.area02_fg,
            area03_fg: v.area03_fg,
            area04_fg: v.area04_fg,
            area05_fg: v.area05_fg,
            area06_fg: v.area06_fg,
            area07_fg: v.area07_fg,
            area08_fg: v.area08_fg,
            area09_fg: v.area09_fg,
            area10_fg: v.area10_fg,
          };
        });
        setAreaList(resAreaList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ユーザ一覧レコード取得 (編集時に発火)
  const getRecordUserList = (userId: string) => {
    let responseData: setUserInfo;
    return api
      .get(API_URL.GET_RECORD_USER_LIST_API, { params: { id: userId } })
      .then((response) => {
        responseData = {
          userId: response.data.actualResult.userId,
          userName: response.data.actualResult.userName,
          mailAddress: response.data.actualResult.mailAddress,
          roleId: response.data.actualResult.roleId,
          password: "",
          confirmPassword: "",
          targetArea01: Number(response.data.actualResult.targetArea01),
          targetArea02: Number(response.data.actualResult.targetArea02),
          targetArea03: Number(response.data.actualResult.targetArea03),
          targetArea04: Number(response.data.actualResult.targetArea04),
          targetArea05: Number(response.data.actualResult.targetArea05),
          targetArea06: Number(response.data.actualResult.targetArea06),
          targetArea07: Number(response.data.actualResult.targetArea07),
          targetArea08: Number(response.data.actualResult.targetArea08),
          targetArea09: Number(response.data.actualResult.targetArea09),
          targetArea10: Number(response.data.actualResult.targetArea10),
          remarks: response.data.actualResult.remarks,
        } as setUserInfo;

        if (isClosing == false) {
          setUserInfo(responseData);
        }
      })
      .catch((err) => {
        console.log(err);
        setUserInfo(initializeUserListInfo);
      });
  };

  const getroll = () => {
    let responseData: role[] = [];
    const userId = userIdForEdit;
    return api
      .post(API_URL.GET_ROLE, { params: { id: userId } })
      .then((response) => {

        responseData = response.data;
        setUserRoles(responseData.map((v) => v.role_id));
        if (responseData === undefined) {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function setRoleId(id: number) {
    let roleIdString = ("00" + id.toString()).slice(-2);
    if (roleIdString == "aN") roleIdString = "";
    const tmpRoleInfo: setUserInfo = Object.assign({}, userListInfo);
    tmpRoleInfo.roleId = roleIdString;
    if (isClosing == false) {
      setUserInfo(tmpRoleInfo);
    }
  }

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const tmpUserInfo: setUserInfo = Object.assign({}, userListInfo);

    switch (name) {
      case "userId":
        tmpUserInfo.userId = value;
        break;
      case "userName":
        tmpUserInfo.userName = value;
        break;
      case "mailAddress":
        tmpUserInfo.mailAddress = value;
        break;
      case "password":
        tmpUserInfo.password = value;
        break;
      case "confirmPassword":
        tmpUserInfo.confirmPassword = value;
        break;
      case "eria01":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea01 = 1;
          //checkBox.check1 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea01 = 0;
          //checkBox.check1 = false;
        }
        //tmpUserInfo.targetArea01 = isNaN(parseInt(value))?0:parseInt(value); // valueの値がbool値か確認する
        break;
      case "eria02":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea02 = 1;
          //checkBox.check2 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea02 = 0;
          //checkBox.check2 = false;
        }
        //tmpUserInfo.targetArea02 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria03":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea03 = 1;
          //checkBox.check3 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea03 = 0;
          //checkBox.check3 = false;
        }
        //tmpUserInfo.targetArea03 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria04":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea04 = 1;
          //checkBox.check4 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea04 = 0;
          //checkBox.check4 = false;
        }
        //tmpUserInfo.targetArea04 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria05":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea05 = 1;
          //checkBox.check5 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea05 = 0;
          //checkBox.check5 = false;
        }
        //tmpUserInfo.targetArea05 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria06":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea06 = 1;
          //checkBox.check6 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea06 = 0;
          //checkBox.check6 = false;
        }
        //tmpUserInfo.targetArea06 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria07":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea07 = 1;
          //checkBox.check7 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea07 = 0;
          //checkBox.check7 = false;
        }
        //tmpUserInfo.targetArea07 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria08":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea08 = 1;
          //checkBox.check8 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea08 = 0;
          //checkBox.check8 = false;
        }
        //tmpUserInfo.targetArea08 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria09":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea09 = 1;
          //checkBox.check9 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea09 = 0;
          //checkBox.check9 = false;
        }
        //tmpUserInfo.targetArea09 = isNaN(parseInt(value))?0:parseInt(value);
        break;
      case "eria10":
        if (parseInt(value) == 0) {
          tmpUserInfo.targetArea10 = 1;
          //checkBox.check10 = true;
        } else if (parseInt(value) == 1) {
          tmpUserInfo.targetArea10 = 0;
          //checkBox.check10 = false;
        }
        //tmpUserInfo.targetArea10 = isNaN(parseInt(value))?0:parseInt(value);
        break;
    }

    if (isClosing == false) {
      setUserInfo(tmpUserInfo);
    }
  }

  function bytes2(str: string) {
    return encodeURIComponent(str).replace(/%../g, "x").length;
  }

  const SaveClose = () => {
    if (mode === modalMode.registerMode) {
      InsertRecord();
    } else {
      UpdateRecord(userIdForEdit);
    }
    // } else {
    //   setDialogMessage("パスワードかユーザIDの入力を確認してください");
    //   setDigOpen(true);
    // }
  };

  const safeUpperStr = /[A-Z]/;
  const safelowerStr = /[a-z]/;
  const safeNumberStr = /[\d]/;
  const safeIdMarkStr = /[_-]/;
  const safeIdStr = /^[a-zA-Z\d_-]+$/;
  const safePasswordStr = /^[a-zA-Z\d]+$/;
  const safeMailStr = /^[a-zA-Z0-9@_.-]+$/;
  /**
   * 入力した情報が正しいかどうか判定する
   * @returns true:NG,false:OK
   */
  const isCorrectInfo = (): [boolean, ErrorInput] => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (userListInfo === undefined) {
      return [error, tempInputErrors];
    }

    if (userListInfo?.userName == "") {
      error = true;
      tempInputErrors.userName = true;
    }

    if (
      userListInfo?.userId == "" ||
      userListInfo?.userId.length < 8 ||
      !safeIdStr.test(userListInfo?.userId)
    ) {
      error = true;
      tempInputErrors.userId = true;
    }

    if (
      userListInfo?.mailAddress == "" ||
      !safeMailStr.test(userListInfo?.mailAddress)
    ) {
      error = true;
      tempInputErrors.mailAddress = true;
    }

    if (userListInfo?.roleId == "") {
      error = true;
      tempInputErrors.roleId = true;
    } else if (userListInfo?.roleId === "20") {
      // エリア毎計画提出者
      const tmpUserListInfo = Object.values(userListInfo);
      const targetAreaValues = tmpUserListInfo.filter((v) => v === 1);
      if (targetAreaValues.length > 1) {
        // エリアが1つ以上選択されている場合はエラー
        error = true;
        tempInputErrors.roleId = true;
      }
    }

    if (isPassDigOpen == true || mode == modalMode.registerMode) {
      if (
        userListInfo?.password == "" ||
        userListInfo?.password.length < 12 ||
        userListInfo?.password == userListInfo?.userId ||
        userListInfo?.password != userListInfo?.confirmPassword ||
        !safeUpperStr.test(userListInfo?.password) ||
        !safelowerStr.test(userListInfo?.password) ||
        !safeNumberStr.test(userListInfo?.password) ||
        !safePasswordStr.test(userListInfo?.password)
      ) {
        error = true;
        tempInputErrors.password = true;
      }
    }
    // if (
    //   userListInfo?.userName == "" ||
    //   userListInfo?.userId == "" ||
    //   userListInfo?.mailAddress == "" ||
    //   userListInfo?.roleId == "" ||
    //   userListInfo?.password == ""
    // ) {
    //   error = true;
    // }
    setInputError(tempInputErrors);
    // setしても次のレンダリングまで値が更新されないので、setした値を返して受け取り側でエラー処理を行なう
    return [error, tempInputErrors];
  };

  /**
   * チェックボックスの活性・非活性制御
   */
  const isDisabled = (AreaId: string): boolean => {
    switch (AreaId) {
      case AREA_ID.HOKKAIDO:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area01_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.TOHOKU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area02_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.TOKYO:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area03_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.CHUBU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area04_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.HOKURIKU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area05_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.KANSAI:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area06_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.CHUGOKU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area07_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.SHIKOKU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area08_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.KYUSYU:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area09_fg) === 1) {
            return false;
          }
        }
        break;
      case AREA_ID.OKINAWA:
        for (let i = 0; i < areaList.length; i++) {
          if (Number(areaList[i].area10_fg) === 1) {
            return false;
          }
        }
        break;
      default:
        break;
    }
    return true;
  };

  //新規登録
  const InsertRecord = () => {
    const errors: [boolean, ErrorInput] = isCorrectInfo();

    if (errors[0] === true) {
      if (errors[1].roleId === true) {
        setDialogMessage(
          languageContext.words.user_setting_err_role_area_plan_submitter
        );
        setDigOpen(true);
      } else {
        setDialogMessage(languageContext.words.setting_failed_new_registration);
        setDigOpen(true);
      }
    } else if (errors[0] === false) {
      setIsLoading(true);
      api
        .post(API_URL.SET_USER_LIST_API, userListInfo)
        .then((response) => {
          setJobClear(true);
          setDialogMessage(languageContext.words.user_registered);
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.user_failed_registration);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // 更新
  async function UpdateRecord(userId: string) {
    const errors: [boolean, ErrorInput] = isCorrectInfo();
    if (errors[0] === true) {
      if (errors[1].roleId === true) {
        setDialogMessage(
          languageContext.words.user_setting_err_role_area_plan_submitter
        );
        setDigOpen(true);
      } else {
        setDialogMessage(languageContext.words.setting_failed_update);
        setDigOpen(true);
      }
    } else if (errors[0] === false) {
      const updateUserInfo = {
        userId: userIdForEdit,
        ...userListInfo,
      } as setUserInfo;
      setIsLoading(true);
      await api
        .post(API_URL.UPDATE_RECORD_USER_LIST_API, updateUserInfo, {
          params: { id: userId },
        })
        .then(async (response) => {
          //ユーザーロール再セット
          await getUserRole();
          //更新した
          setJobClear(true);
          setDialogMessage(languageContext.words.user_updated);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.user_failed_update);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });

      (async () => {
        setBusinessModel(await getBusinessModel());
      })();
    }
  }

  // ユーザのロール取得し、コンテキストに再セット
  async function getUserRole() {
    api
      .get(API_URL.GET_USER_ROLE)
      .then((res) => {
        roleContext.setRole(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let g_deleteUser: string;

  function checkUserDelete(userId: string) {
    // 削除API呼出
    const deleteUserInfo = {
      userId: userIdForEdit,
      ...userListInfo,
    } as setUserInfo;

    g_deleteUser = userId;

    api
      .post(API_URL.CHECK_DELETE_USER, deleteUserInfo, {
        params: { userId: userId },
      })
      .then((response) => {
        if (response.data.enableDelete == true) {
          // 削除可能
          UserDelete(g_deleteUser);
        } else {
          // 削除不可
          setJobClear(true);
          setDialogMessage(languageContext.words.user_cannot_delete);
          setDigOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.user_failed_delete);
        setDigOpen(true);
      });
  }

  async function UserDelete(userId: string) {
    // 削除API呼出
    const deleteUserInfo = {
      userId: userIdForEdit,
      ...userListInfo,
    } as setUserInfo;
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_RECORD_USER_LIST_API, deleteUserInfo, {
        params: { id: userId },
      })
      .then((response) => {
        //.log("削除しました。", response.data.result);
        setJobClear(true);
        setDialogMessage(languageContext.words.user_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.user_failed_delete);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  }
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={
        mode === modalMode.registerMode
          ? languageContext.words.new_registration
          : languageContext.words.user_editing
      }
      open={toOpen}
      // onAcceptLeft={() => UserSave()}
      onAcceptLeft={() => SaveClose()}
      onAcceptRight={() => checkUserDelete(userIdForEdit)}
      onClose={() => HandleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.registerMode
          ? NumOfButton.Single
          : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack direction="column" sx={{ width: "550px" }}>
        <CustomBoxBig>
          <FormLabel required>{languageContext.words.user}ID</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={userListInfo?.userId}
            onChange={onChangeValue}
            name="userId"
            disabled={mode === modalMode.registerMode ? false : true}
            inputProps={{
              maxLength: 50,
            }}
            FormHelperTextProps={{
              sx: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              title: languageContext.words.setting_available_characters_1,
            }}
            error={inputError.userId}
            helperText={languageContext.words.setting_available_characters_1}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <CustomBoxBig>
          <FormLabel required>{languageContext.words.user_name}</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={userListInfo?.userName}
            onChange={onChangeValue}
            name="userName"
            inputProps={{
              maxLength: 16,
            }}
            error={inputError.userName}
            helperText={languageContext.words.setting_character_limit_1}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <CustomBoxBig>
          <FormLabel required>{languageContext.words.mail_address}</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={userListInfo?.mailAddress}
            onChange={onChangeValue}
            name="mailAddress"
            error={inputError.mailAddress}
            helperText={languageContext.words.setting_available_characters_2}
            FormHelperTextProps={{
              sx: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              title: languageContext.words.setting_available_characters_2,
            }}
            inputProps={{
              maxLength: 31,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <CustomBoxSmall>
          <FormGroup>
            <FormLabel required>{languageContext.words.role}</FormLabel>
          </FormGroup>
          <CustomSelectListSmall
            key={"user_roll"}
            value={userListInfo?.roleId}
            options={roleOptions(userRoles, false)}
            onChange={setRoleId}
            error={inputError.roleId}
          />
        </CustomBoxSmall>
        <Button
          style={{
            display:
              isPassDigOpen == true || mode == modalMode.registerMode
                ? "none"
                : undefined,
          }}
          onClick={() => setIsPassDigOpen(true)}
        >
          {languageContext.words.change_simple}
        </Button>
        {isPassDigOpen && (
          <>
            <CustomBoxBig
              sx={{
                display:
                  !isPassDigOpen && mode === modalMode.editMode
                    ? "none"
                    : undefined,
              }}
            >
              <CustomModalTypography
                gutterBottom
                style={{
                  display:
                    !isPassDigOpen && mode === modalMode.editMode
                      ? "none"
                      : undefined,
                }}
              >
                <FormLabel required>
                  {languageContext.words.new_password}
                </FormLabel>
              </CustomModalTypography>
              <CustomTextFieldBig
                style={{
                  display:
                    !isPassDigOpen && mode === modalMode.editMode
                      ? "none"
                      : undefined,
                }}
                type="password"
                value={userListInfo?.password}
                onChange={onChangeValue}
                name="password"
                inputProps={{
                  maxLength: 64,
                }}
                error={inputError.password}
                FormHelperTextProps={{
                  sx: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  title: languageContext.words.setting_available_characters_3,
                }}
                helperText={
                  languageContext.words.setting_available_characters_3
                }
              ></CustomTextFieldBig>
            </CustomBoxBig>
            <CustomBoxBig
              sx={{
                display:
                  !isPassDigOpen && mode === modalMode.editMode
                    ? "none"
                    : undefined,
              }}
            >
              <CustomModalTypography
                gutterBottom
                style={{
                  display:
                    !isPassDigOpen && mode === modalMode.editMode
                      ? "none"
                      : undefined,
                }}
              >
                <FormLabel required>
                  {languageContext.words.new_password_confirmation}
                </FormLabel>
              </CustomModalTypography>
              <CustomTextFieldBig
                style={{
                  display:
                    !isPassDigOpen && mode === modalMode.editMode
                      ? "none"
                      : undefined,
                }}
                type="password"
                value={userListInfo?.confirmPassword}
                onChange={onChangeValue}
                name="confirmPassword"
                inputProps={{
                  maxLength: 64,
                }}
                error={inputError.password}
              ></CustomTextFieldBig>
            </CustomBoxBig>
            <Button
              style={{ display: !isPassDigOpen ? "none" : undefined }}
              onClick={() => setIsPassDigOpen(false)}
            >
              {languageContext.words.cancel}
            </Button>
          </>
        )}

        <FormLabel required>
          {languageContext.words.areas_of_responsibility}
        </FormLabel>
        <FormGroup>
          <Grid container spacing={2} columns={10}>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.HOKKAIDO)}
                    value={userListInfo?.targetArea01}
                    checked={userListInfo?.targetArea01 == 1}
                    onChange={onChangeValue}
                    name="eria01"
                  />
                }
                label={languageContext.words.hokkaido_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.TOHOKU)}
                    value={userListInfo?.targetArea02}
                    checked={userListInfo?.targetArea02 == 1}
                    onChange={onChangeValue}
                    name="eria02"
                  />
                }
                label={languageContext.words.tohoku_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.TOKYO)}
                    value={userListInfo?.targetArea03}
                    checked={userListInfo?.targetArea03 == 1}
                    onChange={onChangeValue}
                    name="eria03"
                  />
                }
                label={languageContext.words.tokyo_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.CHUBU)}
                    value={userListInfo?.targetArea04}
                    checked={userListInfo?.targetArea04 == 1}
                    onChange={onChangeValue}
                    name="eria04"
                  />
                }
                label={languageContext.words.chubu_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.HOKURIKU)}
                    value={userListInfo?.targetArea05}
                    checked={userListInfo?.targetArea05 == 1}
                    onChange={onChangeValue}
                    name="eria05"
                  />
                }
                label={languageContext.words.hokuriku_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.KANSAI)}
                    value={userListInfo?.targetArea06}
                    checked={userListInfo?.targetArea06 == 1}
                    onChange={onChangeValue}
                    name="eria06"
                  />
                }
                label={languageContext.words.kansai_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.CHUGOKU)}
                    value={userListInfo?.targetArea07}
                    checked={userListInfo?.targetArea07 == 1}
                    onChange={onChangeValue}
                    name="eria07"
                  />
                }
                label={languageContext.words.chugoku_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.SHIKOKU)}
                    value={userListInfo?.targetArea08}
                    checked={userListInfo?.targetArea08 == 1}
                    onChange={onChangeValue}
                    name="eria08"
                  />
                }
                label={languageContext.words.shikoku_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.KYUSYU)}
                    value={userListInfo?.targetArea09}
                    checked={userListInfo?.targetArea09 == 1}
                    onChange={onChangeValue}
                    name="eria09"
                  />
                }
                label={languageContext.words.kyushu_region}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isDisabled(AREA_ID.OKINAWA)}
                    value={userListInfo?.targetArea10}
                    checked={userListInfo?.targetArea10 == 1}
                    onChange={onChangeValue}
                    name="eria10"
                  />
                }
                label={languageContext.words.okinawa_region}
              />
            </Grid>
          </Grid>
          <FormHelperText sx={{ color: "red" }}>
            {languageContext.words.more_than_one_area}
          </FormHelperText>
        </FormGroup>

        <CustomBoxBig>
          <FormLabel>{languageContext.words.remarks}</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={userListInfo?.remarks}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setUserInfo((prev) =>
                prev
                  ? {
                      ...prev,
                      remarks: e.target.value,
                    }
                  : prev
              );
            }}
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetUserList;
