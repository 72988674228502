import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import "./css/UserList.css";

// ユーザ一覧
import SetUserList from "./SetUserList";
import { Box, Typography } from "@mui/material";
import SetSaleDetailList from "./SetSaleDetailList";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import { SaleDetailInfo } from "../../types/master/SaleDetailList";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export enum modalMode {
  editMode,
  addMode,
}

const SaleDetailList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const { role } = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDからエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  //showがtrueになった時モーダルが開かれる
  const [show, setShow] = useState(false);
  //DBから取得したデータをSaleDetasに格納して描画
  const [saleDetas, setSaleDetas] = useState<SaleDetailInfo[]>([]);

  // 初期化
  useEffect(() => {
    (async () => {
      Promise.all([checkEditRoll(), requestInfo()]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);
  //編集ボタンを押した行のBGコード情報
  const [saleId, setSaleId] = useState<number>(0);

  // 表示（現状モーダル表示を管理するshowがfalseになったタイミングで描写）
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show) return;
    setIsLoading(true);
    (async () => {
      requestInfo().finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show]);

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setSaleId(0);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (SaleId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setSaleId(SaleId);

    setShow(true);
  };

  const requestInfo = () => {
    // 販売先グループ内訳情報一覧取得
    return api
      .get(API_URL.GET_SALEDETAS)
      .then((res) => {
        setSaleDetas(res.data.result);
      })
      .catch((err) => {
        setSaleDetas([]);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    return api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: role } })
      .then((res) => {
        setEnableEdit(res.data);
      });
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ height: "40px" }}>
          {enableEdit && (
            <NewRegistrationButton
              variant="outlined"
              onClick={() => HandleOpenForAddNew()}
            >
              {languageContext.words.new_registration}
            </NewRegistrationButton>
          )}
        </Box>
        {SetSaleDetailList(show, HandleClose, mode, saleId)}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.sales_destinations_name}(
                {languageContext.words.breakdown})
              </StyledTableHeaderBig>
              <StyledTableHeaderBig>
                {languageContext.words.affiliated_sales_destinations}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.supplier_area}
              </StyledTableHeaderSmall>

              <StyledTableHeaderBig>
                {languageContext.words.power_plant}
              </StyledTableHeaderBig>

              <StyledTableHeaderSmall>
                {languageContext.words.supply_destination_area}
              </StyledTableHeaderSmall>

              <StyledTableHeaderBig>
                {languageContext.words.demander}
              </StyledTableHeaderBig>

              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {saleDetas?.map((saleDeta) => (
              <TableRow key={saleDeta.saleName}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={saleDeta.saleName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {saleDeta.saleName}
                    </Typography>
                    {enableEdit && (
                      <ModalButton
                        variant="outlined"
                        onClick={() => HandleOpenForEdit(saleDeta.saleId)}
                      >
                        {languageContext.words.edit}
                      </ModalButton>
                    )}
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  sx={{ textAlign: "left !important" }}
                  title={saleDeta.saleGroupName}
                >
                  {saleDeta.saleGroupName}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={convertIdToAreaName(saleDeta.areaId)}
                >
                  {convertIdToAreaName(saleDeta.areaId)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  sx={{ textAlign: "left !important" }}
                  title={saleDeta.powerName}
                >
                  {saleDeta.powerName}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={convertIdToAreaName(saleDeta.targetAreaId)}
                >
                  {convertIdToAreaName(saleDeta.targetAreaId)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  sx={{ textAlign: "left !important" }}
                  title={saleDeta.demandName}
                >
                  {saleDeta.demandName}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={saleDeta.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {saleDeta.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

export default SaleDetailList;
