import React, { useState, useEffect, useContext } from "react";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList, {
  CustomMultipleSelectList,
} from "../../common/customComponents/CustomSelectList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
// import ログイン用Context
import "./PowerForecastList.css";
import {
  reqPowerForecastList,
  resPowerForecastList,
} from "../../../types/occto/PowerForecastList";
import { areIntervalsOverlapping, format, parse } from "date-fns";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import {
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
} from "../../../common/url";
import { useAreaUtility } from "../../../common/area";
import { AREA_ID } from "../../../common/constant";
import { usePowerPlantUtility } from "../../../common/powerEnergy";
import { useAuthedApi } from "../../../common/axios";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

export interface searchInfo {
  id: number;
  name: string;
}

// 共通コンポーネントカスタマイズ
// ヘッダー ライトグレー
const PowerForecastListStyledTableHeaderLG = styled(StyledTableHeaderLG)`
  && {
    height: 42px;
    padding: 0px;
  }
`;
// セル
const PowerForecastListStyledTableCell = styled(StyledTableCell)`
  && {
    height: 42px;
    padding: 0px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
  }
`;

// API URL
// 発電計画一覧
const GET_POWER_FORCAST_LIST_URL = "/getPowerForecastList";
// 3連検索コンポーネント情報取得
const GET_POWER_FORECAST_INFO_URL = "/getPowerForecastInfoList";
export interface searchInfo {
  id: number;
  name: string;
}

/**
 * 発電計画一覧画面
 * @returns
 */
const PowerForecastList = () => {
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // カラーモード
  const palletContextBase = useContext(PaletteContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // エリア
  const { setAreaId } = useContext(AreaContext);
  const api = useAuthedApi();

  // セレクトリスト
  const [selectArea, setSelectArea] = useState<string>(AREA_ID.NONE); //エリア
  const [selectPowerBg, setSelectPowerBg] = useState<number>(0); // 発電BG
  const [selectPowerPlant, setSelectPowerPlant] = useState<number>(0); //発電所

  // URLパラメータ取得
  const [serchParams] = useSearchParams();

  const [allPowerPlantList, setAllPowerPlantList] = useState<powerDemandInfo[]>(
    []
  );

  //エリア一覧
  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  // 発電計画一覧
  const [powerForecastListItems, setpowerForecastListItems] = useState<
    resPowerForecastList[]
  >([]);

  const { convertIdToEnergyName } = usePowerPlantUtility();

  // 描画監視
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  const [error, setError] = useState<any>();

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // 初期表示情報セット
  useEffect(() => {
    (async () => {
      //発電所情報一括取得
      Promise.all([getPowerPlantList()]).finally(() => {
        setIsLoaded(false);
        leavePageContext.setBlockLeave(false);
      });
    })();
  }, []);

  // エリア変更時
  useEffect(() => {
    // 発電計画一覧取得
    if (
      (selectArea === AREA_ID.NONE &&
        selectPowerBg !== 0 &&
        selectPowerPlant !== 0) ||
      (selectArea === AREA_ID.NONE &&
        selectPowerBg !== 0 &&
        selectPowerPlant === 0)
    ) {
      //なにもしない
      setIsLoaded(false);
    } else {
      if (allPowerPlantList.length) {
        (async () => {
          Promise.all([
            getPowerForcastListData(
              selectArea,
              selectPowerBg,
              selectPowerPlant
            ),
          ]);
        })();
      }
    }
  }, [dateContext, allPowerPlantList]);

  //発電所情報一括取得
  //3連コンポーネント対応用
  const getPowerPlantList = async () => {
    setIsLoaded(true);
    api
      .get(GET_POWER_FORECAST_INFO_URL)
      .then((res) => {
        setAllPowerPlantList(
          res.data.map((v: any) => {
            return {
              areaId: v.areaId,
              bgId: v.bgId,
              bgName: v.bgName,
              powerDemandId: v.powerId,
              powerDemandName: v.powerName,
            };
          })
        );
        setIsLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 発電計画一覧
  const getPowerForcastListData = async (
    areaId: string,
    powerBgId: number,
    powerId: number
  ) => {
    const areaList = [...new Set(allPowerPlantList.map((v) => v.areaId))];
    const requestParam: reqPowerForecastList = {
      areaIds:
        areaId === AREA_ID.NONE
          ? areaList.filter((e) => e !== AREA_ID.NONE)
          : areaList.filter((e) => e === areaId),
      powerBgId: powerBgId,
      powerId: powerId,
      date: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    setIsLoaded(true);
    api
      .get(GET_POWER_FORCAST_LIST_URL, { params: requestParam })
      .then((res) => {
        setpowerForecastListItems(res.data);
        setIsLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 画面遷移関数
  const navigate = useNavigate();
  function transitToOtherPage(url: string) {
    navigate(url);
  }

  //ページレンダリング
  return (
    <div
      key={"powerForecastData_div"}
      className={`cn-main-display ${
        palletContextBase.PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Box
        key={"powerForecastData_Box_SelectLists"}
        sx={{ marginBottom: 2, display: "flex" }}
      >
        <CustomMultipleSelectList
          powerFg={true}
          list={allPowerPlantList}
          areaId={selectArea}
          bgId={selectPowerBg}
          powerDemandId={selectPowerPlant}
          handleValueChangeArea={setSelectArea}
          handleValueChangeBG={setSelectPowerBg}
          handleValueChangePowerDemand={setSelectPowerPlant}
          getData={getPowerForcastListData}
        ></CustomMultipleSelectList>
        <GlobalDatePicker
          key={"powerForecastData_GlobalDatePicker"}
          isDipsTodayBtn={true}
        />
      </Box>

      <TableContainer
        key={"powerForecastData_TableContainer"}
        sx={{
          width: "100%",
          height: "90%",
          "&::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "transparent",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "10px",
          },
        }}
      >
        <StyledTable
          key={"powerForecastData_StyledTable"}
          sx={{ width: "100%" }}
          stickyHeader
        >
          <TableHead key={"powerForecastData_Header"}>
            <TableRow>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_area"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.area}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_plant"}
                sx={{ width: "19%" }}
                align="center"
              >
                {languageContext.words.power_plant}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_method"}
                sx={{ width: "10%" }}
                align="center"
              >
                {languageContext.words.power_gen_method}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_bg"}
                sx={{ width: "19%" }}
                align="center"
              >
                {languageContext.words.power_gen_bg}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_createDate"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.save_date}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_user"}
                sx={{ width: "12%" }}
                align="center"
              >
                {languageContext.words.updater}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_Buttons"}
                sx={{ width: "22%" }}
                align="center"
              ></PowerForecastListStyledTableHeaderLG>
            </TableRow>
          </TableHead>

          <TableBody>
            {powerForecastListItems.map((row, index) => (
              <TableRow key={"powerForecastData_Row" + index}>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_areaName"}
                  align="center"
                  title={convertIdToAreaName(row.areaId)}
                >
                  {convertIdToAreaName(row.areaId)}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_plantName"}
                  align="left"
                  title={row.powerName}
                >
                  {row.powerName}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_solar"}
                  align="center"
                  title={convertIdToEnergyName(row.powerCategory)}
                >
                  {convertIdToEnergyName(row.powerCategory)}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_bgName"}
                  align="left"
                  title={row.powerBgName}
                >
                  {row.powerBgName}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_createDate"}
                  align="center"
                  title={
                    row.saveDate
                      ? languageContext.convertDateToLocalizedString(
                          new Date(row.saveDate),
                          "HH:mm:ss"
                        )
                      : ""
                  }
                >
                  {row.saveDate
                    ? languageContext.convertDateToLocalizedString(
                        new Date(row.saveDate),
                        "HH:mm:ss"
                      )
                    : ""}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_user"}
                  align="center"
                  title={row.saveUser}
                >
                  {row.saveUser}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_buttonCell"}
                  align="center"
                >
                  <StyledButton
                    key={
                      "powerForecastData_" +
                      index +
                      "_transitToPowerForecastButton"
                    }
                    sx={{ marginRight: 1.5 }}
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId);
                      transitToOtherPage(
                        POWER_FORECAST_URL +
                          "?areaId=" +
                          row.areaId +
                          "&bgId=" +
                          row.powerBgId +
                          "&plantId=" +
                          row.powerId
                      );
                    }}
                  >
                    {languageContext.words.powerforecast}
                  </StyledButton>
                  <StyledButton
                    key={
                      "powerForecastData_" +
                      index +
                      "_transitToPowerForecastPlanButton"
                    }
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId);
                      transitToOtherPage(
                        POWER_FORECAST_PLAN_URL + "?areaId=" + row.areaId
                      );
                    }}
                  >
                    {languageContext.words.power_gen_and_sales_plan}
                  </StyledButton>
                </PowerForecastListStyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <CustomModalProgress open={isLoaded} />
      <CustomDialog
        title={languageContext.words.power_gen_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

export default PowerForecastList;
