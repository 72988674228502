import styled from "styled-components";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../styledComponents/styledTable";
import { TableContainer, TableHead } from "@mui/material";

//設定で使うテーブルコンテナー
export const StyledMasterTableContainer = styled(TableContainer)`
  min-width: 100%;
  height: 100%;
  display: flexed;
`;

//設定で使うテーブル
export const StyledMasterTable = styled(StyledTable)`
  table-layout: fixed;
  width: 100%;
  position: sticky;
  left: 0;
  z-index: 1;
  bordercollapse: separate;
`;

//設定で使うテーブルヘッダー
export const StyledMasterTableHeader = styled(TableHead)`
  background: rgb(220, 230, 241);
  position: sticky;
  top: 0;
  z-index: 1;
  height: 42px;
  padding: 0px;
`;

//設定で使うテーブルヘッダーセル

//大
export const StyledTableHeaderBig = styled(StyledTableHeaderLG)`
  width: auto;
  height: 42px;

  && {
    text-align: center;
    padding: 0px;
  }
`;

//中
export const StyledTableHeaderMid = styled(StyledTableHeaderLG)`
  width: 280px;
  height: 42px;
  && {
    text-align: center;
    padding: 0px;
  }
`;

//小
export const StyledTableHeaderSmall = styled(StyledTableHeaderLG)`
  width: 140px;
  height: 42px;
  && {
    text-align: center;
    padding: 0px;
  }
`;

//設定で使うテーブルセル
export const StyledMasterTableCell = styled(StyledTableCell)`
  height: 42px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
  && {
    text-align: center;
    padding: 0px 8px 0px 8px;
  }
`;
