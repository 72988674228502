import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import "./css/UserList.css";

// ユーザ一覧
import SetUserList from "./SetUserList";
import { Box, Typography } from "@mui/material";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import { userInfo } from "../../types/master/UserList";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useRoleUtility } from "../../common/role";
import { API_URL, ROLE_ID } from "../../common/constant";
import { useAuthedApi } from "../../common/axios";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export enum modalMode {
  editMode,
  registerMode,
}

const UserList = () => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);
  //todo ロールはDBから取得してidはロールIDそのままを持っておく

  //通信エラー
  const api = useAuthedApi();

  type role = {
    role_id: string;
    role_nm: string;
  };

  const { roleOptions, convertIdToRoleName } = useRoleUtility();

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);

  //セレクトタブのState
  const [roll, setRoll] = useState<string>(ROLE_ID.ALL);
  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  //ロールリスト
  const [userRoles, setUserRoles] = useState<string[]>([]);

  // showがtrueになったらモーダルが開かれる
  const [show, setShow] = useState(false);
  // const [users, setUser] = useState<any[]>([{ user_id: "", role_id: null }]);
  const [users, setUser] = useState<userInfo[]>([
    { userId: "", userName: "", roleName: "", roleId: "" },
  ]);

  // 編集/新規登録のモードを管理 0:編集 1:新規登録
  const [mode, setMode] = useState<modalMode>(modalMode.registerMode);

  // 編集ボタンを押した行のユーザID情報
  const [userIdForEdit, setUserIdForEdit] = useState<string>("");

  // 表示
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show) return;
    setIsLoading(true);
    (async () => {
      Promise.all([
        checkEditRoll(),
        getroll(),
        requestUserList(roll, userRoles),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show]);

  // 設定モーダル開く//
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => {
    setShow(false);
    requestUserList(roll, userRoles);
  };

  // 設定モーダルを新規登録モードで開く
  const HandleOpenRegister = () => {
    setMode(modalMode.registerMode);
    setUserIdForEdit("");
    setShow(true);
  };

  // 設定モーダルを編集モードで開く
  const HandleOpenEdit = (userId: string) => {
    setMode(modalMode.editMode);
    setUserIdForEdit(userId);
    setShow(true);
  };

  // ユーザ一覧取得
  const requestUserList = (selectrole: string, rollInfos: string[]) => {
    let responseData: userInfo[] = [];
    return api
      .get(API_URL.USER_LIST_API)
      .then((response) => {
        responseData = response.data.actualResult as userInfo[];
        records(responseData, selectrole, rollInfos);
      })
      .catch((err) => {
        //records([{ userId: "", userName: "", roleName: "" }] as never, 0);
        console.log(err);
      });
  };

  // 初期表示
  useEffect(() => {
    (async () => {
      Promise.all([
        checkEditRoll(),
        getroll(),
        requestUserList(roll, userRoles),
      ]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  const getroll = () => {
    let responseData: role[] = [];
    api
      .get(API_URL.GET_ROLE)
      .then((response) => {

        responseData = response.data;
        //初回は全て
        const roleIdInfos: string[] = [];
        roleIdInfos.push(ROLE_ID.ALL);

        for (let i = 0; i < responseData.length; i++) {
          roleIdInfos.push();
        }
        responseData.forEach((v) => {
          roleIdInfos.push(v.role_id);
        });
        setUserRoles(roleIdInfos.map((v) => v.toString()));
        requestUserList(
          ROLE_ID.ALL,
          roleIdInfos.map((v) => v.toString())
        );
        if (responseData === undefined) {
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        } else {
          setEnableEdit(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // テーブルの列データ
  const records = (
    responseData: userInfo[],
    selectrole: string,
    rollInfos: string[]
  ) => {
    const UserList: userInfo[] = [];
    //UserList = responseData;

    //選択しているロールと同じものを抽出
    for (let i = 0; i < responseData.length; i++) {
      if (responseData[i].roleId == selectrole || selectrole == ROLE_ID.ALL) {
        //ロール名がいっしょならリストにつめる
        UserList.push(responseData[i]);
      }
    }

    if (UserList === undefined) {
      return;
    }

    setUser(UserList);
  };

  return (
    <div className="cn-setting-display">
      <Box sx={{ width: "100%", height: "20%", display: "flex" }}>
        <CustomSelectList
          label={languageContext.words.role_all}
          value={roll}
          options={roleOptions(userRoles)}
          onChange={(e: string) => {
            setRoll(e);
            requestUserList(e, userRoles);
          }}
        />
        {enableEdit && (
          <NewRegistrationButton
            onClick={() => HandleOpenRegister() /*新規登録*/}
            variant="outlined"
          >
            {languageContext.words.new_registration}
          </NewRegistrationButton>
        )}
        {SetUserList(show, HandleClose, mode, userIdForEdit)}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig style={{ width: "601px" }}>
                {languageContext.words.user}ID
              </StyledTableHeaderBig>
              <StyledTableHeaderBig style={{ width: "901px" }}>
                {languageContext.words.user_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall style={{ width: "250px" }}>
                {languageContext.words.role}
              </StyledTableHeaderSmall>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.userId}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={user.userId}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {user.userId}
                    </Typography>
                    {enableEdit && (
                      <ModalButton
                        onClick={() => HandleOpenEdit(user.userId)}
                        variant="outlined"
                      >
                        {languageContext.words.edit} {/*編集ボタン*/}
                      </ModalButton>
                    )}
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={user.userName}
                  sx={{ textAlign: "left !important" }}
                >
                  {user.userName}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={user.roleName}>
                  {convertIdToRoleName(user.roleId)}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

export default UserList;
