import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./css/UserList.css";

// ユーザ一覧
import Box from "@mui/material/Box";

import { StyledTableCell } from "../common/styledComponents/styledTable";
import {
  Button,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LanguageContext } from "../common/localization/localization";
import { addDays, format, subDays } from "date-fns";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import SaveDataButton from "../common/customComponents/SaveDataButton";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

interface jepxCertifiCate {
  startDate: string;
  endDate: string;
  password: string;
  certificateFile: string;
  fileName: string;
  jepxUserId: string;
}

interface jepxUser {
  jepxUserId: string;
  partId: string;
  jepxUserNum: number;
  loginName: string;
  password: string;
  userName: string;
}

interface jepxUserRes {
  userId: string;
  loginName: string;
  userName: string;
  password: string;
}

interface jepxPartData {
  partId: string;
  partName: string;
}

// 証明書登録情報
interface certificateData {
  startDate: string;
  endDate: string;
  password: string;
  certificateFile: string;
  fileName: string;
  areaId?: string;
}

export const StyledButton = styled(Button)`
  && {
    min-height: 24px;
    width: auto;
    color: white;
    font-size: 13px;
    padding 6px 16px;
  }
`;

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  partId: boolean;
  partName: boolean;
  repUserId: boolean;
  repLoginName: boolean;
  repLoginPass: boolean;
  repUserName: boolean;
  repFile: boolean;
  repFilePass: boolean;
  addUserId: boolean;
  addLoginName: boolean;
  addLoginPass: boolean;
  addUserName: boolean;
  addFile: boolean;
  addFilePass: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  partId: false,
  partName: false,
  repUserId: false,
  repLoginName: false,
  repLoginPass: false,
  repUserName: false,
  repFile: false,
  repFilePass: false,
  addUserId: false,
  addLoginName: false,
  addLoginPass: false,
  addUserName: false,
  addFile: false,
  addFilePass: false,
};

const safeIdStr = /^[0-9]*$/;
const safeLoginNameStr = /^[0-9a-zA-Z]*$/;

const SetMarketUser = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  const [users, setUser] = useState<any[]>();

  // 参加者
  const [partId, setPartId] = useState<string>("");
  const [partName, setPartName] = useState<string>("");

  // 代表ユーザ
  const [repUserId, setRepUserId] = useState<string>("");
  const [repLoginName, setRepLoginName] = useState<string>("");
  const [repLoginPass, setRepLoginPass] = useState<string>("");
  // const [repConfLoginPass, setRepConfLoginPass] = useState<string>("");
  const [repUserName, setRepUserName] = useState<string>("");

  // 追加ユーザ
  const [addUserId, setAddUserId] = useState<string>("");
  const [addLoginName, setAddLoginName] = useState<string>("");
  const [addLoginPass, setAddLoginPass] = useState<string>("");
  // const [addConfLoginPass, setAddConfLoginPass] = useState<string>("");
  const [addUserName, setAddUserName] = useState<string>("");

  // 代表証明書
  const [repStartDate, setRepStartDate] = useState<Date>(new Date());
  const [repEndDate, setRepEndDate] = useState<Date>(addDays(new Date(), 1));
  const [repFilePass, setRepFilePass] = useState<string>("");
  // const [repConfirmFilePass, setRepConfirmFilePass] = useState<string>("");
  const [repFile, setRepFile] = useState<File | null>(null);
  const [repFileName, setRepFileName] = useState<string>("");

  // 追加証明書
  const [addStartDate, setAddStartDate] = useState<Date>(new Date());
  const [addFilePass, setAddFilePass] = useState<string>("");
  const [addEndDate, setAddEndDate] = useState<Date>(addDays(new Date(), 1));
  // const [addConfirmFilePass, setAddConfirmFilePass] = useState<string>("");
  const [addFile, setAddFile] = useState<File | null>(null);
  const [addFileName, setAddFileName] = useState<string>("");

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);

  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const inputAddRef = React.useRef<any>(null);
  const inputRepRef = React.useRef<any>(null);

  // 参加者情報取得
  const getPartData = async () => {
    api
      .get(API_URL.GET_JEPX_PARTICIPANT)
      .then((res) => {
        const response: jepxPartData = res.data[0];
        setPartId(response.partId);
        setPartName(response.partName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 代表ユーザ情報取得
  const getRepUserData = async () => {
    api
      .get(API_URL.GET_JEPX_USER, { params: { userNo: 0 } })
      .then((res) => {
        const response: jepxUserRes = res.data[0];
        setRepUserId(response.userId);
        setRepLoginName(response.loginName);
        setRepLoginPass(response.password);
        setRepUserName(response.userName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 追加ユーザ情報取得
  const getAddUserData = async () => {
    api
      .get(API_URL.GET_JEPX_USER, { params: { userNo: 1 } })
      .then((res) => {
        const response: jepxUserRes = res.data[0];
        setAddUserId(response.userId);
        setAddLoginName(response.loginName);
        setAddLoginPass(response.password);
        setAddUserName(response.userName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 代表ユーザ証明書データ取得
  const getRepCertificate = async () => {
    api
      .get(API_URL.GET_JEPX_CERTIFICATE, { params: { userNo: 0 } })
      .then((res) => {
        const certificateData: certificateData = res.data[0];
        setRepStartDate(new Date(certificateData.startDate));
        setRepEndDate(new Date(certificateData.endDate));
        setRepFileName(certificateData.fileName.split("/").pop() as string);
        setRepFilePass(certificateData.password);
      })
      .catch((err) => console.log(err));
  };

  // 追加ユーザ証明書データ取得
  const getAddCertificate = async () => {
    api
      .get(API_URL.GET_JEPX_CERTIFICATE, { params: { userNo: 1 } })
      .then((res) => {
        const certificateData: certificateData = res.data[0];
        setAddStartDate(new Date(certificateData.startDate));
        setAddEndDate(new Date(certificateData.endDate));
        setAddFileName(certificateData.fileName.split("/").pop() as string);
        setAddFilePass(certificateData.password);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    //ロール検査
    checkEditRoll();
    (async () => {
      Promise.all([
        getPartData(),
        getRepUserData(),
        getAddUserData(),
        getRepCertificate(),
        getAddCertificate(),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, []);

  // 編集可能ユーザか確認
  const checkEditRoll = async () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // テーブルの列データ
  function records(responseData: never[]) {
    let UserList: any[] = [];
    const datas: any[] = [];
    UserList = responseData;

    if (UserList === undefined) {
      return;
    }

    setUser(UserList);
  }

  const StyledTableHeaderLG = styled(TableCell)(() => ({
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  }));

  if (users === undefined) {
    records([{ user_id: "test", role_id: 99 }] as never);
    return <></>;
  }

  // ファイル選択時
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    // ファイル選択がキャンセルされた時は undefined
    const file = e.target.files !== null ? e.target.files[0] : null;
    setFile(() => {
      return file ? file : null;
    });
  };

  // 保存処理をまとめて呼び出す
  const saveDatas = async () => {
    setIsLoading(true);
    (async () => {
      Promise.all([postJepxData()]).finally(() => {
        setIsLoading(false);
      });
    })();
  };

  // 保存ボタン押下時
  const handleClick = async () => {
    //ここから
    if (isAllSafeInfo()) {
      await saveDatas();
    } else {
      if (addInfoSend === true) {
        setDialogMessage(languageContext.words.jepx_user_setting_err_message_1);
        setDigOpen(true);
      } else {
        setDialogMessage(languageContext.words.jepx_user_setting_err_message_2);
        setDigOpen(true);
      }
      setDigOpen(true);
    }
  };

  //必要な内容全て書かれているかチェック
  let addInfoSend = false;
  function isAllSafeInfo() {
    addInfoSend = false;
    let safeInfo = true;
    const tempInputErrors = Object.assign({}, initErrorInput);
    //JEPX参加者情報と代表ユーザ情報が正しく登録されているか確認
    if (partId == "" || !safeIdStr.test(partId)) {
      safeInfo = false;
      tempInputErrors.partId = true;
    }
    if (partName == "") {
      safeInfo = false;
      tempInputErrors.partName = true;
    }
    if (repUserId == "" || !safeIdStr.test(repUserId)) {
      safeInfo = false;
      tempInputErrors.repUserId = true;
    }
    if (repLoginName == "" || !safeLoginNameStr.test(repLoginName)) {
      safeInfo = false;
      tempInputErrors.repLoginName = true;
    }
    if (repUserName == "") {
      safeInfo = false;
      tempInputErrors.repUserName = true;
    }
    if (!IsSafePassword(repLoginPass)) {
      safeInfo = false;
      tempInputErrors.repLoginPass = true;
    }

    if (repFileName == "") {
      safeInfo = false;
      tempInputErrors.repFile = true;
    }
    if (!IsSafePassword(repFilePass)) {
      safeInfo = false;
      tempInputErrors.repFilePass = true;
    }

    //追加ユーザ部分
    //追加ユーザのユーザID入力されていたら追加ユーザ登録する意思があると見なす
    if (addUserId !== "") {
      addInfoSend = true;
      if (addUserId == "" || !safeIdStr.test(addUserId)) {
        safeInfo = false;
        tempInputErrors.addUserId = true;
      }
      if (addLoginName == "" || !safeLoginNameStr.test(addLoginName)) {
        safeInfo = false;
        tempInputErrors.addLoginName = true;
      }
      if (addUserName == "") {
        safeInfo = false;
        tempInputErrors.addUserName = true;
      }
      if (!IsSafePassword(addLoginPass)) {
        tempInputErrors.addLoginPass = true;
      }

      if (addFileName == "") {
        safeInfo = false;
        tempInputErrors.addFile = true;
      }
      if (!IsSafePassword(addFilePass)) {
        safeInfo = false;
        tempInputErrors.addFilePass = true;
      }
    }

    setInputError(tempInputErrors);
    return safeInfo;
  }

  //JPEXパスワード制限
  const IsSafePassword = (password: string) => {
    let result = true;
    const safeIdStr = /^[a-zA-Z\d]+$/;
    if (!safeIdStr.test(password)) {
      result = false;
    }

    if (password.length == 0) {
      result = false;
    }

    return result;
  };

  // JEPX参加者、代表ユーザ情報保存
  const postJepxData = () => {
    if (
      repUserId === "" ||
      partId === "" ||
      repLoginName === "" ||
      repLoginPass === "" ||
      repUserName === "" ||
      partName === "" ||
      repFileName === "" ||
      repUserId === undefined
    ) {
      return;
    }
    if (
      !IsSafePassword(repLoginPass) ||
      repLoginName.length > 20 ||
      repUserName.length > 20
    ) {
      return;
    }

    const repUserData: jepxUser = {
      jepxUserId: repUserId,
      partId: partId,
      jepxUserNum: 0,
      loginName: repLoginName,
      password: repLoginPass,
      userName: repUserName,
    };

    if (repFile !== null) {
      // ファイルがアップロードされている場合
      const reader = new FileReader();
      reader.readAsDataURL(repFile);
      reader.onload = function () {
        const val = String(reader.result);
        const fileName = repFile.name;
        const repCertificateData: jepxCertifiCate = {
          startDate: format(repStartDate, "yyyy-MM-dd"),
          endDate: format(repEndDate, "yyyy-MM-dd"),
          certificateFile: val,
          fileName: fileName,
          password: repFilePass,
          jepxUserId: repUserId,
        };

        const body = {
          partId: partId,
          partName: partName,
          jepxUserData: repUserData,
          certificateData: repCertificateData,
        };
        api
          .post(API_URL.POST_JEPX_DATA, body)
          .then(async (res) => {
            if (
              addUserId !== "" &&
              partId !== "" &&
              addLoginName !== "" &&
              addLoginPass !== "" &&
              addUserName !== "" &&
              addFileName !== "" &&
              addUserId !== undefined &&
              IsSafePassword(addLoginPass) &&
              addLoginName.length <= 20 &&
              addUserName.length <= 20
            ) {
              await postAddJepxUserData();
            } else {
              setDialogMessage(
                languageContext.words.jepx_user_representative_register
              );
              setDigOpen(true);
            }
          })
          .catch((err) => {
            setDialogMessage(languageContext.words.jepx_user_failed_register);
            setDigOpen(true);
          });
      };
    } else {
      const repCertificateData: jepxCertifiCate = {
        startDate: format(repStartDate, "yyyy-MM-dd"),
        endDate: format(repEndDate, "yyyy-MM-dd"),
        certificateFile: "",
        fileName: repFileName,
        password: repFilePass,
        jepxUserId: repUserId,
      };
      const body = {
        partId: partId,
        partName: partName,
        jepxUserData: repUserData,
        certificateData: repCertificateData,
      };

      api
        .post(API_URL.POST_JEPX_DATA, body)
        .then(async (res) => {
          if (
            addUserId !== "" &&
            partId !== "" &&
            addLoginName !== "" &&
            addLoginPass !== "" &&
            addUserName !== "" &&
            addFileName !== "" &&
            addUserId !== undefined &&
            IsSafePassword(addLoginPass) &&
            addLoginName.length <= 20 &&
            addUserName.length <= 20
          ) {
            await postAddJepxUserData();
          } else {
            setDialogMessage(
              languageContext.words.jepx_user_representative_register
            );
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.jepx_user_failed_register);
          setDigOpen(true);
        });
    }
  };

  // 追加ユーザ登録
  const postAddJepxUserData = async () => {
    const addData: jepxUser = {
      jepxUserId: addUserId,
      partId: partId,
      jepxUserNum: 1,
      loginName: addLoginName,
      password: addLoginPass,
      userName: addUserName,
    };
    if (addFile !== null) {
      // ファイルがアップロードされている場合
      const file = addFile as File;

      const addReader = new FileReader();
      addReader.readAsDataURL(file);
      addReader.onload = function () {
        const val = String(addReader.result);
        const fileName = file.name;
        const addCertificateData: jepxCertifiCate = {
          startDate: format(addStartDate, "yyyy-MM-dd"),
          endDate: format(addEndDate, "yyyy-MM-dd"),
          certificateFile: val,
          fileName: fileName,
          password: addFilePass,
          jepxUserId: addUserId,
        };

        const body = {
          jepxUserData: addData,
          certificateData: addCertificateData,
        };

        api
          .post(API_URL.POST_ADDITIONAL_JEPX_DATA, body)
          .then((res) => {
            setDialogMessage(languageContext.words.jepx_user_all_register);
            setDigOpen(true);
          })
          .catch((err) => {
            setDialogMessage(
              languageContext.words.jepx_additional_user_failed_register
            );
            setDigOpen(true);
          });
      };
    } else {
      const addCertificateData: jepxCertifiCate = {
        startDate: format(addStartDate, "yyyy-MM-dd"),
        endDate: format(addEndDate, "yyyy-MM-dd"),
        certificateFile: "",
        fileName: addFileName,
        password: addFilePass,
        jepxUserId: addUserId,
      };

      const body = {
        jepxUserData: addData,
        certificateData: addCertificateData,
      };

      api
        .post(API_URL.POST_ADDITIONAL_JEPX_DATA, body)
        .then((res) => {
          setDialogMessage(languageContext.words.jepx_user_all_register);
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(
            languageContext.words.jepx_additional_user_failed_register
          );
          setDigOpen(true);
        });
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "4%",
          height: "20%",
          display: "flex",
          paddingBottom: "10px",
          marginLeft: "auto",
        }}
      >
        <SaveDataButton
          sx={{ height: "40px" }}
          hidden={!enableEdit}
          onAccept={() => handleClick()}
        />
      </Box>
      <TableContainer>
        <Stack direction="row">
          <Typography gutterBottom>
            {languageContext.words.jepx_participant}
          </Typography>
        </Stack>

        {/* JEPX参加者 */}
        <Table
          sx={{ width: "22%", padding: "10px 20px", marginBottom: "20px" }}
          aria-label="userList"
        >
          <TableHead
            sx={{
              minWidth: 650,
              background: "#F0F0F0",
              position: "sticky",
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
                style={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <FormLabel required>
                  {languageContext.words.participant_id}
                </FormLabel>
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_4}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
                style={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <FormLabel required>
                  {languageContext.words.participant_name}
                </FormLabel>
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell
                style={{
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="left"
                sx={{ height: "42px", padding: "0px", width: "8%" }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 3,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={partId}
                  onChange={(e) => {
                    setPartId(e.target.value);
                  }}
                  error={inputError.partId}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ height: "42px", padding: "0px", width: "8%" }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={partName}
                  onChange={(e) => setPartName(e.target.value)}
                  error={inputError.partName}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* JEPX代表ユーザ */}
        <Stack direction="row">
          <Typography gutterBottom>
            {languageContext.words.jepx_representative_user}
          </Typography>
        </Stack>

        <Table
          sx={{ width: "100%", padding: "10px 20px", marginBottom: "20px" }}
          aria-label="userList"
        >
          <TableHead
            sx={{
              minWidth: 650,
              background: "#F0F0F0",
              position: "sticky",
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>{languageContext.words.user}ID</FormLabel>
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_4}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>
                  {languageContext.words.login_name}
                </FormLabel>
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>
                  {languageContext.words.user_name}
                </FormLabel>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>{languageContext.words.password}</FormLabel>
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>
                  {languageContext.words.client_certificate}
                </FormLabel>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.file}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.effective_date_start_date}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.expiration_date}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                <FormLabel required>
                  {languageContext.words.certificate_password}
                </FormLabel>
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell
                style={{
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 6,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={repUserId}
                  onChange={(e) => {
                    setRepUserId(e.target.value);
                  }}
                  error={inputError.repUserId}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={repLoginName}
                  onChange={(e) => {
                    setRepLoginName(e.target.value);
                  }}
                  error={inputError.repLoginName}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={repUserName}
                  onChange={(e) => setRepUserName(e.target.value)}
                  error={inputError.repUserName}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 7,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={repLoginPass}
                  onChange={(e) => {
                    setRepLoginPass(e.target.value);
                  }}
                  error={inputError.repLoginPass}
                />
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "180px",
                  alignContent: "center",
                  //display: "flex",
                }}
              >
                <Button
                  disabled={!enableEdit ? true : false}
                  variant="outlined"
                  sx={{
                    //border: `1px solid lightblue`,
                    margin: "3px",
                    width: "95%",
                  }}
                  onClick={() => inputRepRef.current.click()}
                  color={inputError.repFile === true ? "error" : "primary"}
                >
                  {languageContext.words.file_select}
                </Button>

                <Input
                  sx={{ width: "0px", visibility: "hidden" }}
                  id="file1"
                  name="file1"
                  inputProps={{
                    style: {
                      width: "0px",
                      padding: 1,
                      height: "0px",
                      textAlign: "left",
                      // marginRight: "2px",
                      backgroundColor: "lightgray",
                      visibility: "hidden",
                    },
                  }}
                  // hidden={true}
                  inputRef={inputRepRef}
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeHandler(e, setRepFile);
                    setRepFileName(
                      e.target.files === null ? "" : e.target.files[0].name
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  minWidth: "200px",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    borderBottom: "1px solid black",
                    margin: "2px",
                    width: "100%",
                  }}
                >
                  {repFileName}
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "220px",
                  textAlign: "center",
                }}
              >
                <CustomDatePickerTryal
                  enableEdit={enableEdit}
                  setValue={setRepStartDate}
                  value={repStartDate}
                  maxDate={subDays(repEndDate, 1)}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "220px",
                  textAlign: "center",
                }}
              >
                <CustomDatePickerTryal
                  enableEdit={enableEdit}
                  setValue={setRepEndDate}
                  value={repEndDate}
                  minDate={addDays(repStartDate, 1)}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxLength: 127,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={repFilePass}
                  onChange={(e) => {
                    setRepFilePass(e.target.value);
                  }}
                  error={inputError.repFilePass}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {/* JEPX追加ユーザ */}
        <Stack direction="row">
          <Typography gutterBottom>
            {languageContext.words.jepx_additional_user}
          </Typography>
          <FormHelperText sx={{ textAlign: "center" }}>
            {languageContext.words.jepx_user_setting_message}
          </FormHelperText>
        </Stack>
        <Table
          sx={{ width: "100%", padding: "10px 20px", marginBottom: "20px" }}
          aria-label="userList"
        >
          <TableHead
            sx={{
              minWidth: 650,
              background: "#F0F0F0",
              position: "sticky",
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.user}ID
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_4}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.login_name}
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.user_name}
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.jepx_user_setting_required}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.password}
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.client_certificate}
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.jepx_user_setting_required}
                </FormHelperText>
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.file}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.effective_date_start_date}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.expiration_date}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                style={{
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.certificate_password}
                <FormHelperText sx={{ textAlign: "center", color: "red" }}>
                  {languageContext.words.setting_available_characters_5}
                </FormHelperText>
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell
                style={{
                  borderLeft: "1px solid rgba(224, 224, 224, 1)",
                }}
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 6,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={addUserId}
                  onChange={(e) => {
                    setAddUserId(e.target.value);
                  }}
                  error={inputError.addUserId}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={addLoginName}
                  onChange={(e) => {
                    setAddLoginName(e.target.value);
                  }}
                  error={inputError.addLoginName}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "auto",
                  textAlign: "center",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={addUserName}
                  onChange={(e) => setAddUserName(e.target.value)}
                  error={inputError.addUserName}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  textAlign: "center",
                  width: "auto",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      maxLength: 7,
                      min: 0,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={addLoginPass}
                  onChange={(e) => {
                    setAddLoginPass(e.target.value);
                  }}
                  error={inputError.addLoginPass}
                />
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{
                  height: "42px",
                  padding: "0px",
                  width: "180px",
                  alignContent: "center",
                  //display: "flex",
                }}
              >
                <Button
                  disabled={!enableEdit ? true : false}
                  variant="outlined"
                  sx={{
                    //border: "1px",
                    margin: "3px",
                    width: "95%",
                  }}
                  onClick={() => inputAddRef.current.click()}
                  color={inputError.addFile === true ? "error" : "primary"}
                >
                  {languageContext.words.file_select}
                </Button>

                <Input
                  sx={{ width: "0px", visibility: "hidden" }}
                  id="file2"
                  name="file2"
                  inputProps={{
                    style: {
                      width: "0px",
                      padding: 1,
                      height: "30px",
                      textAlign: "left",
                      // marginRight: "2px",
                      backgroundColor: "lightgray",
                      visibility: "hidden",
                    },
                  }}
                  // hidden={true}
                  inputRef={inputAddRef}
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeHandler(e, setAddFile);
                    setAddFileName(
                      e.target.files === null ? "" : e.target.files[0].name
                    );
                  }}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  textAlign: "center",
                  width: "auto",
                  minWidth: "200px",
                }}
              >
                <Typography
                  sx={{
                    borderBottom: "1px solid black",
                    margin: "2px",
                    width: "100%",
                  }}
                >
                  {addFileName}
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  textAlign: "center",
                  width: "220px",
                }}
              >
                <CustomDatePickerTryal
                  enableEdit={enableEdit}
                  setValue={setAddStartDate}
                  value={addStartDate}
                  maxDate={subDays(addEndDate, 1)}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  textAlign: "center",
                  width: "220px",
                }}
              >
                <CustomDatePickerTryal
                  enableEdit={enableEdit}
                  setValue={setAddEndDate}
                  value={addEndDate}
                  minDate={addDays(addStartDate, 1)}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  height: "42px",
                  padding: "0px",
                  textAlign: "center",
                  width: "auto",
                }}
              >
                <TextField
                  disabled={!enableEdit ? true : false}
                  sx={{ margin: "3px" }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxLength: 127,
                      style: {
                        padding: 1,
                        height: "30px",
                        textAlign: "left",
                        marginRight: "2px",
                      },
                    },
                  }}
                  value={addFilePass}
                  onChange={(e) => {
                    setAddFilePass(e.target.value);
                  }}
                  error={inputError.addFilePass}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

export default SetMarketUser;
