import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./css/SetUserList.css";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./PowerBp";
import { powerBpInfo } from "../../types/master/PowerBp";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { useAuthedApi } from "../../common/axios";
import { API_URL, BP_KIND } from "../../common/constant";
import { CommonResponse } from "../../types/common/Api";
import { Height, Margin, Padding, SpaceBar } from "@mui/icons-material";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bpName: boolean;
  bpCode: boolean;
  bpKind: boolean;
  targetArea: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bpName: false,
  bpCode: false,
  bpKind: false,
  targetArea: false,
};

// 初期化用
const initBpInfo = {
  bpId: 0,
  bpKind: 0,
  bpName: "",
  bpCode: "",
  targetArea: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  remarks: "",
};

//引数で編集/新規登録 を判断する。編集の場合情報もらう
const SetPowerBp = (
  toOpen: boolean,
  HandleClose: () => void,
  mode: modalMode,
  bpId: number
) => {
  //言語データ切り替え用データコンテキスト
  const { words } = useContext(LanguageContext);

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // 通信エラー
  const api = useAuthedApi();

  //事業者種別のリスト
  const BpKind = useMemo(
    () => [
      { id: 0, name: words.setting_unselected },
      { id: 1, name: words.power_bp_setting_bp_kind_1 },
      { id: 2, name: words.power_bp_setting_bp_kind_2 },
    ],
    [words]
  );

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [powerBpInfo, setPowerBpInfo] = useState<powerBpInfo>();

  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  // ダイアログ管理
  const [dialogMessage, setDialogMessage] = useState("");
  const [digTittle, setDigTittle] = useState("");
  const [digType, setDigType] = useState(ButtonType.OkOnly);
  const [digOpen, setDigOpen] = useState(false);

  // 成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]);

  // 表示初期化
  useEffect(() => {
    if (toOpen) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        getRecordUserList().finally(() => {
          setIsLoading(false);
        });
      } else {
        setPowerBpInfo({
          bpId: 0,
          bpKind: 0,
          bpName: "",
          bpCode: "",
          targetArea: [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ],
          remarks: "",
        });
        setIsLoading(false);
      }
    } else {
      setPowerBpInfo({
        bpId: 0,
        bpKind: 0,
        bpName: "",
        bpCode: "",
        targetArea: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        remarks: "",
      });
      setInputError(initErrorInput);
    }
  }, [toOpen]);

  // 発電事業者詳細取得API
  const getRecordUserList = () => {
    return api
      .get<undefined, AxiosResponse<CommonResponse<powerBpInfo>>>(
        API_URL.GET_BP_DETAIL,
        { params: { bpId } }
      )
      .then((res) => {
        setPowerBpInfo(res.data.result);
      })
      .catch((err) => {
        setPowerBpInfo(initBpInfo);
      });
  };

  // 発電事業者新規登録API
  const InsertRecord = () => {
    setIsLoading(true);
    api
      .post(API_URL.INSERT_BP, powerBpInfo)
      .then((res) => {
        setJobClear(true);
        setDialogMessage(words.power_bp_setting_new_registered);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(words.power_bp_setting_failed_register);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 発電事業者更新API
  const UpdateRecord = () => {
    setIsLoading(true);
    api
      .post(API_URL.UPDATE_BP, powerBpInfo)
      .then((res) => {
        if (res.data.result) {
          setJobClear(res.data.result);
          setDialogMessage(words.power_bp_setting_updated);
          setDigOpen(true);
        } else {
          setDialogMessage(words.delete_subordinate_master_confirmation);
          setDigTittle(words.setting_warning);
          setDigType(ButtonType.OkCancel);
          setDigOpen(true);
        }
      })
      .catch((err) => {
        setDialogMessage(words.power_bp_setting_failed_update);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //発電事業者削除API
  const CheckPowerBpDelete = async (id: number) => {
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_BP, powerBpInfo)
      .then((res) => {
        setJobClear(true);
        setDialogMessage(words.power_bp_setting_deleted);
      })
      .catch((err) => {
        setDialogMessage(words.power_bp_setting_delete_failed);
      })
      .finally(() => {
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
    setDigOpen(true);
  };

  // 更新・下位マスタ削除
  async function updateRelatedPowerBp() {
    setIsLoading(true);
    await api
      .post(API_URL.UPDATE_RERATED_BP, powerBpInfo)
      .then((res) => {
        setJobClear(res.data.result);
        setDigTittle("");
        setDigType(ButtonType.OkOnly);
        setDialogMessage(words.power_bp_setting_updated);
        setDigOpen(true);
      })
      .catch((err) => {
        setDigTittle("");
        setDigType(ButtonType.OkOnly);
        setDialogMessage(words.power_bp_setting_failed_update);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  }

  function onChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const tmp: powerBpInfo = Object.assign({}, powerBpInfo);

    switch (name) {
      case "bpKind":
        tmp.bpKind = parseInt(value);
        break;
      case "bpName":
        tmp.bpName = value;
        break;
      case "bpCode":
        tmp.bpCode = value;
        break;
      case "area01":
        tmp.targetArea[0] = e.target.checked;
        break;
      case "area02":
        tmp.targetArea[1] = e.target.checked;
        break;
      case "area03":
        tmp.targetArea[2] = e.target.checked;
        break;
      case "area04":
        tmp.targetArea[3] = e.target.checked;
        break;
      case "area05":
        tmp.targetArea[4] = e.target.checked;
        break;
      case "area06":
        tmp.targetArea[5] = e.target.checked;
        break;
      case "area07":
        tmp.targetArea[6] = e.target.checked;
        break;
      case "area08":
        tmp.targetArea[7] = e.target.checked;
        break;
      case "area09":
        tmp.targetArea[8] = e.target.checked;
        break;
      case "area10":
        tmp.targetArea[9] = e.target.checked;
        break;
    }
    console.log("初期値", initBpInfo);
    setPowerBpInfo(tmp);
  }

  //カスタムセレクトリストはonchangeに数値を返すようになっているので
  //別で用意
  function onChangeBpKind(id: number) {
    const tmp: powerBpInfo = Object.assign({}, powerBpInfo);
    tmp.bpKind = id;
    setPowerBpInfo(tmp);
    console.log("初期値", initBpInfo);
  }

  const SaveClose = () => {
    if (isCorrectInfo()) {
      if (mode === modalMode.addMode) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    } else {
      setDialogMessage(words.setting_failed_new_registration);
      setDigOpen(true);
    }
  };

  // 登録内容チェック
  const isCorrectInfo = (): boolean => {
    let error = true;

    const tempInputErrors = Object.assign({}, initErrorInput);
    if (!powerBpInfo?.bpName) {
      error = false;
      tempInputErrors.bpName = true;
    }
    if (!powerBpInfo?.bpCode || powerBpInfo?.bpCode.length !== 4) {
      error = false;
      tempInputErrors.bpCode = true;
    }

    if (!powerBpInfo?.bpKind) {
      error = false;
      tempInputErrors.bpKind = true;
    }

    const checkCnt = powerBpInfo?.targetArea.filter((v) => v).length ?? 0;
    if (
      (powerBpInfo?.bpKind !== BP_KIND.OTHER && checkCnt === 0) ||
      (powerBpInfo?.bpKind === BP_KIND.OTHER && checkCnt !== 1)
    ) {
      error = false;
      tempInputErrors.targetArea = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  return (
    <>
      <CustomSettingModal
        title={words.power_bp_setting_title}
        open={toOpen}
        onAcceptLeft={() => SaveClose()}
        onAcceptRight={() => CheckPowerBpDelete(bpId)}
        onClose={() => HandleClose()}
        btnTextLeft={words.registration}
        btnTextRight={words.delete}
        btnType={
          mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
        }
        isAcceptedKey={isLoading}
      >
        <Stack direction="column" sx={{ width: "550px" }} spacing={0}>
          <CustomBoxBig>
            <FormLabel required>{words.power_bp_name}</FormLabel>
            <CustomTextFieldBig
              type="text"
              value={powerBpInfo?.bpName}
              onChange={onChangeValue}
              name="bpName"
              disabled={mode === modalMode.editMode}
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bpName}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <FormLabel required>{words.power_bp_code}</FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={powerBpInfo?.bpCode}
              onChange={onChangeValue}
              name="bpCode"
              inputProps={{
                maxLength: 4,
              }}
              FormHelperTextProps={{
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                title: words.setting_available_characters_6,
              }}
              error={inputError.bpCode}
              helperText={words.setting_available_characters_6}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>{words.new_power_kind}</FormLabel>
            </FormGroup>
            <CustomSelectListSmall
              value={powerBpInfo?.bpKind}
              options={BpKind}
              onChange={onChangeBpKind}
              name="bpKind"
              disabled={mode === modalMode.editMode}
              error={inputError.bpKind}
            ></CustomSelectListSmall>
          </CustomBoxSmall>

          <FormControl required error={inputError.targetArea}>
            <FormGroup>
              <FormLabel required>{words.target_area}</FormLabel>
              <Grid container spacing={2} columns={10}>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[0]}
                        onChange={onChangeValue}
                        name="area01"
                      />
                    }
                    label={words.hokkaido_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[1]}
                        onChange={onChangeValue}
                        name="area02"
                      />
                    }
                    label={words.tohoku_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[2]}
                        onChange={onChangeValue}
                        name="area03"
                      />
                    }
                    label={words.tokyo_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[3]}
                        onChange={onChangeValue}
                        name="area04"
                      />
                    }
                    label={words.chubu_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[4]}
                        onChange={onChangeValue}
                        name="area05"
                      />
                    }
                    label={words.hokuriku_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[5]}
                        onChange={onChangeValue}
                        name="area06"
                      />
                    }
                    label={words.kansai_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[6]}
                        onChange={onChangeValue}
                        name="area07"
                      />
                    }
                    label={words.chugoku_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[7]}
                        onChange={onChangeValue}
                        name="area08"
                      />
                    }
                    label={words.shikoku_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[8]}
                        onChange={onChangeValue}
                        name="area09"
                      />
                    }
                    label={words.kyushu_region}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={powerBpInfo?.targetArea[9]}
                        onChange={onChangeValue}
                        name="area10"
                      />
                    }
                    label={words.okinawa_region}
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormHelperText sx={{ color: "red" }}>
              {powerBpInfo?.bpKind === BP_KIND.OTHER
                ? words.only_one_area
                : words.more_than_one_area}
            </FormHelperText>
          </FormControl>

          <CustomBoxBig>
            <FormLabel>{words.remarks}</FormLabel>
            <CustomTextFieldBig
              type="text"
              value={powerBpInfo?.remarks}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setPowerBpInfo((prev) =>
                  prev
                    ? {
                        ...prev,
                        remarks: e.target.value,
                      }
                    : prev
                );
              }}
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Stack>
        <CustomModalProgress open={isLoading} />
      </CustomSettingModal>
      <CustomDialog
        title={digTittle}
        message={dialogMessage}
        buttonType={digType}
        open={digOpen}
        onAccept={() => {
          if (digType === ButtonType.OkCancel) {
            updateRelatedPowerBp();
          }
        }}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default SetPowerBp;
